import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 800px;
  margin: 60px auto;
  background-color: white;
  border-radius: 10px;
  padding: 10px 30px;
  max-height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;
`;

export const Black = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.6);
  z-index: 1000;
`;
