import styled from 'styled-components';

import ImageCardComponent from 'components/ImageCard';
import NewImageCardComponent from 'components/NewCard';
import MenuBarComponent from './MenuBar';
import InfoBarComponent from './InfoBar';

export const Wrapper = styled.div`
  margin: 20px;
`;

export const MenuBar = styled(MenuBarComponent)``;

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-gap: 10px;

  @media (max-width: 768px) {
    grid-template-columns: 100%;
  }
`;

export const ImageCard = styled(ImageCardComponent)`
  width: 100%;
  margin: 10px auto;
`;

export const NewImageCard = styled(NewImageCardComponent)`
  width: 100%;
  margin: 10px auto;
`;

export const SectionHeader = styled.h2`
  text-align: center;
`;

export const NotLoggedIn = styled.h4`
  text-align: center;
  padding-top: 25px;
`;

export const Loading = styled.h2`
  text-align: center;
`;

export const InfoBar = styled(InfoBarComponent)``;
