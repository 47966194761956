import styled from 'styled-components';

export const ButtonComponent = styled.button`
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:not([disabled]) {
    color: #fff;
    background-color: ${({ color }) =>
      color === 'red' ? '#dc3545' : '#007bff'};
    border-color: #007bff;
    cursor: pointer;
  }

  &:hover:not([disabled]) {
    color: #fff;
    background-color: ${({ color }) =>
      color === 'red' ? '#c82333' : '#0069d9'};
    border-color: #0062cc;
  }
`;
