import { Compute } from 'cerebral';
import { state } from 'client/app.cerebral';

import { getAllBadges } from '../utils/common';

export default Compute((get) => {
  const images = get(state.profile.images);
  const user = get(state.app.user);

  return getAllBadges().map((badge) => ({
    key: badge.key,
    color: badge.color,
    icon: badge.icon,
    count: Object.keys(images || {}).filter(
      (imageKey) =>
        images[imageKey].uid === user?.uid &&
        images[imageKey].badge === badge.key
    ).length,
  }));
});
