import React, { Component } from 'react';

type Props = {
  children: React.ReactNode;
};

type State = {
  error: null | Error;
};

export default class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error: Error) {
    this.setState({ error });
  }

  render() {
    if (this.state.error) {
      return this.state.error.message;
    }

    return this.props.children;
  }
}
