import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
`;

export const Filter = styled.div`
  display: flex;
  align-items: center;
  margin: 10px;
`;

export const Input = styled.input`
  width: 24px;
  height: 24px;
`;

export const Text = styled.p``;
