import { Compute } from 'cerebral';
import { state } from 'client/app.cerebral';
import { memberDatetime } from 'utils/common';

export default Compute((get) => {
  const profiles = get(state.profiles);
  const sortBy = get(state.members.sortBy);
  const sortDirection = get(state.members.sortDirection);
  const filters = get(state.members.filters);

  return Object.keys(profiles || {})
    .map((uid) => ({ uid, ...profiles[uid] }))
    .filter((member) => {
      if (filters.hasBeenMembers && !member.memberUntil) return false;
      if (
        filters.currentMembers &&
        ((member.memberUntil &&
          memberDatetime(member.memberUntil) < Date.now()) ||
          !member.memberUntil)
      )
        return false;

      return true;
    })
    .sort((a, b) => {
      switch (sortBy) {
        case 'name':
          return a.name.localeCompare(b.name) * sortDirection;
        case 'email':
          return a.email.localeCompare(b.email) * sortDirection;
        case 'emails':
          return (
            (Object.values(a.mails || {}).filter((c) => c).length -
              Object.values(b.mails || {}).filter((c) => c).length) *
            sortDirection
          );
        case 'terms':
          if (!a.termsAccepted) return -1 * sortDirection;
          if (!b.termsAccepted) return sortDirection;

          return (a.termsAccepted - b.termsAccepted) * sortDirection;
        case 'isAdmin':
          if (!a.isAdmin) return -1 * sortDirection;
          if (!b.isAdmin) return sortDirection;

          return (Number(a.isAdmin) - Number(b.isAdmin)) * sortDirection;
        case 'memberUntil':
          if (!a.memberUntil) return -1 * sortDirection;
          if (!b.memberUntil) return sortDirection;

          return (
            (memberDatetime(a.memberUntil) - memberDatetime(b.memberUntil)) *
            sortDirection
          );
        default:
          return a.name.localeCompare(b.name) * sortDirection;
      }
    });
});
