import React, { useEffect, useRef } from 'react';
import { useText } from 'common/hooks';

import { Scroll, Content, Image } from './elements';
type Props = {
  className?: string;
};
// eslint-disable-next-line max-lines-per-function
export default function ImageCarousel({ className }: Props): React.ReactNode {
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current.refs.container;
    if (scrollContainer) {
      let scrollAnimation = {} as NodeJS.Timer;
      let speed = 1;
      const startScrollAnimation = () => {
        speed = 1;
        clearInterval(scrollAnimation);
        scrollAnimation = setInterval(() => {
          scrollContainer.scrollLeft += speed;
          // 1em gap => 16px offset
          if (
            scrollContainer.scrollLeft >=
            (scrollContainer.scrollWidth + 16) / 2
          ) {
            scrollContainer.scrollLeft = 0;
          }
        }, 20); // Adjust the interval as needed
      };
      const stopScrollAnimation = () => {
        speed = 0;
      };
      startScrollAnimation();
      scrollContainer.addEventListener('mouseenter', stopScrollAnimation);
      scrollContainer.addEventListener('mouseleave', startScrollAnimation);
      scrollContainer.addEventListener('touchstart', stopScrollAnimation);

      return () => {
        clearInterval(scrollAnimation);
        scrollContainer.removeEventListener('mouseenter', stopScrollAnimation);
        scrollContainer.removeEventListener('mouseleave', startScrollAnimation);
        scrollContainer.removeEventListener('touchstart', stopScrollAnimation);
      };
    }

    return undefined;
  }, []);

  return (
    <div className={className}>
      <Scroll ref={scrollContainerRef}>
        <Content>
          <Image
            src={
              'https://firebasestorage.googleapis.com/v0/b/skk-prod.appspot.com/o/images%2Fsmall%2F4bcaaa68-db68-4d3f-ab63-25345a009489.jpg?alt=media&token=db0389f4-793d-4b0f-8b73-176ab5219cc0'
            }
          />
          <Image
            src={
              'https://firebasestorage.googleapis.com/v0/b/skk-prod.appspot.com/o/images%2Fsmall%2F70e05fad-5fcd-450c-9b26-0b774d2af75e.jpg?alt=media&token=857a3af4-fd86-4bdc-a9ff-5bed68d18d62'
            }
          />
          <Image
            src={
              'https://firebasestorage.googleapis.com/v0/b/skk-prod.appspot.com/o/images%2Fsmall%2F463d064c-38c0-40df-a7c1-87e8e468644a.jpg?alt=media&token=6de20f71-2394-431f-8157-50ad94d6a7bb'
            }
          />
          <Image src={'/images/Banner.jpg'} />
          <Image
            src={
              'https://firebasestorage.googleapis.com/v0/b/skk-prod.appspot.com/o/images%2Fsmall%2F4aa68401-4ef7-4111-9ecd-bd2596d1b2ed.jpg?alt=media&token=598ad287-1f77-4817-b7e7-7a5d2f367187'
            }
          />
          <Image
            src={
              'https://firebasestorage.googleapis.com/v0/b/skk-prod.appspot.com/o/images%2Fsmall%2F5a54be1c-4fb8-4f33-8c43-f8f59f59fa69.jpg?alt=media&token=a70b97e2-25f3-4c54-9519-b025aa79dc02'
            }
          />
          <Image
            src={
              'https://firebasestorage.googleapis.com/v0/b/skk-prod.appspot.com/o/images%2Fsmall%2Ff564614c-04d2-46ee-89c5-7a70a167efee.jpg?alt=media&token=7f1e3954-5f4b-4031-b211-e5c92102e35d'
            }
          />
        </Content>
        <Content>
          <Image
            src={
              'https://firebasestorage.googleapis.com/v0/b/skk-prod.appspot.com/o/images%2Fsmall%2F4bcaaa68-db68-4d3f-ab63-25345a009489.jpg?alt=media&token=db0389f4-793d-4b0f-8b73-176ab5219cc0'
            }
          />
          <Image
            src={
              'https://firebasestorage.googleapis.com/v0/b/skk-prod.appspot.com/o/images%2Fsmall%2F70e05fad-5fcd-450c-9b26-0b774d2af75e.jpg?alt=media&token=857a3af4-fd86-4bdc-a9ff-5bed68d18d62'
            }
          />
          <Image
            src={
              'https://firebasestorage.googleapis.com/v0/b/skk-prod.appspot.com/o/images%2Fsmall%2F463d064c-38c0-40df-a7c1-87e8e468644a.jpg?alt=media&token=6de20f71-2394-431f-8157-50ad94d6a7bb'
            }
          />
          <Image src={'/images/Banner.jpg'} />
          <Image
            src={
              'https://firebasestorage.googleapis.com/v0/b/skk-prod.appspot.com/o/images%2Fsmall%2F4aa68401-4ef7-4111-9ecd-bd2596d1b2ed.jpg?alt=media&token=598ad287-1f77-4817-b7e7-7a5d2f367187'
            }
          />
          <Image
            src={
              'https://firebasestorage.googleapis.com/v0/b/skk-prod.appspot.com/o/images%2Fsmall%2F5a54be1c-4fb8-4f33-8c43-f8f59f59fa69.jpg?alt=media&token=a70b97e2-25f3-4c54-9519-b025aa79dc02'
            }
          />
          <Image
            src={
              'https://firebasestorage.googleapis.com/v0/b/skk-prod.appspot.com/o/images%2Fsmall%2Ff564614c-04d2-46ee-89c5-7a70a167efee.jpg?alt=media&token=7f1e3954-5f4b-4031-b211-e5c92102e35d'
            }
          />
        </Content>
      </Scroll>
    </div>
  );
}
