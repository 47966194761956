export default {
  title: 'Om oss',
  firstParagraph: `Studentenes Kameraklubb er et samlepunkt for studenter i 
    Trondheim som er interessert i fotografi. Klubben har en tradisjonsrik historie som strekker seg helt tilbake til 1938. Våre 
    fasiliteter befinner seg på Moholt studentby, hvor vi åpner dørene for alle våre medlemmer. Her kan du slenge deg på sofaen vår,
    slå av en prat med andre fotoentusiaster, og benytte vår stasjonære PC til personlig bilderedigering. Vi er veldig stolte av å
    tilby et eget mørkerom som er fullt utstyrt med alt du trenger til fremkalling av analoge bilder.`,
  history: 'Vår historie',
  secondParagraph: `MARKDOWN: 
  Studentens Kameraklubb ble formelt etablert den 3. mai 1938. Før dette tidspunktet var klubben en del av
  Akademisk Radioklubb (ARK), som hadde etablert sitt eget mørkerom på Studentersamfundet. Ved dannelsen av klubben søkte den nye  
  studentorganisasjonen om å overta ARKs mørkerom. I samme tidsrom søkte SK også om å bli anerkjent som offisielle fotografer
  for både Studentersamfundet og UKA, et privilegium de imidlertid først fikk tildelt i 1947, under UKA-47.
  
  SK nedfelte en dedikert arbeidsgruppe for fotografering på Studentersamfundet, en enhet vi i dag kjenner som Fotogjengen. 
  I denne perioden benyttet klubben et mørkerom som de lånte på Singsaker Studenterhjem. I 1958 ble Fotogjengen innlemmet som en offisiell gruppe på
  Studentersamfundet, en milepæl som også sannsynligvis markerte det tidspunktet da SK ble en selvstendig organisasjon.
  
  Det er viktig å merke seg at ikke alle medlemmene i SK ønsket å vie sin tid til å fotografere for Studentersamfundet. En betydelig andel valgte å forbli 
  i SK for å videreutvikle sine interesser for fotografering som en hobby, snarere enn som en offisiell forpliktelse. Det finnes begrenset informasjon om
  klubbens virksomhet i årene som fulgte denne splittelsen, men vi er engasjert i å utforske og avdekke mer om denne historien.
  
  I 2016 gjennomgikk SK en betydelig overgang ved å forlate sitt tidligere lokale i Herman Krags vei til fordel for sitt nåværende hjem i
  Prestekragevegen. I 2018 markerte kameraklubben sitt 80-årsjubileum med en særskilt utstilling på Realfagsbiblioteket på Gløshaugen. Utstillingen viste
  både bilder tatt av klubbens nåværende medlemmer, samt utvalgte fotografier fra klubbens omfattende, åttigårige arkiv.`,
  board: 'Styret',
  linkFG: 'Mye av denne informasjonen er hentet fra Fotogjengen.',
  leder: 'Leder',
  nestleder: 'Nestleder',
  okonomi: 'Økonomiansvarlig',
  kjeller: 'Kjelleransvarlig',
  kroniken: 'Krønikenredaktør',
  arrangementer: 'Arrangementskomiteen',
  itkom: 'IT-komitéen',
  statutes: 'Vedtekter',
  statutesLink: `Trykk her for å se våre vedtekter`,
};
