import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 10px auto;
  max-width: 500px;
`;

type IconProps = {
  visible?: boolean;
};

export const Icon = styled(FontAwesomeIcon)<IconProps>`
  margin: 5px;
  margin-bottom: 1px;
  font-size: 16px;
  color: ${({ visible }) =>
    visible === undefined ? '#333' : visible === true ? 'green' : 'red'};
`;

export const Text = styled.p``;

export const InnerWrapper = styled.button`
  display: block;
  border: 0;
  padding: 0;
  background-color: inherit;
  text-align: center;
  cursor: pointer;
  margin: 0 10px;
  flex-basis: 0;
  flex-grow: 1;

  &:hover {
    color: gray;

    & > svg {
      color: gray;
    }
  }
`;
