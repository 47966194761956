import { type Auth, getIdToken } from 'firebase/auth';
import { FirebaseProviderError } from './errors';

export default function uploadAdminImageFactory(auth: Auth) {
  return function uploadAdminImageFunction(
    this: any,
    file: File,
    { size, path }: { size: string; path: string }
  ) {
    const user = auth.currentUser;

    if (!user) throw new FirebaseProviderError(new Error('Not authenticated'));

    const formData = new FormData();

    formData.append('image', file, file.name);
    formData.append('size', size);
    formData.append('path', path);

    const { cloudFunctionUrl } = this.context.controller.getState(
      'appContainer.config'
    );

    return getIdToken(user)
      .then((token) =>
        fetch(`${cloudFunctionUrl}/requests-admin-uploadImage`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        })
      )
      .then((response) => {
        if (response.ok) {
          return response.json().then((jsonResponse) => ({
            response: jsonResponse,
            statusCode: response.status,
          }));
        }

        throw new FirebaseProviderError(new Error('Request error'));
      });
  };
}
