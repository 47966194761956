import styled from 'styled-components';

import ImageHandler from 'common/ImageHandler';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;
export const Image = styled(ImageHandler)`
  height: 90vh;
  width: 90vw;
  margin: 0 auto;
  max-width: 100%;
  background-color: transparent;
`;
