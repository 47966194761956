import React, { useEffect } from 'react';
import { connect, ConnectedProps } from '@cerebral/react';
import { state, sequences } from 'client/app.cerebral';
import {
  faArrowCircleLeft,
  faArrowCircleRight,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';

import {
  Wrapper,
  Title,
  Image,
  Photographer,
  ImageCounter,
  HeaderWrapper,
  Arrow,
  TitleWrapper,
  Icon,
} from './elements';

import { useText } from 'common/hooks';

type Props = {
  className?: string;
};

const deps = {
  image: state.competition.slideImage,
  competitionPresentationClicked: sequences`competition.competitionPresentationClicked`,
  arrowLeftClicked: sequences`competition.arrowLeftClicked`,
  arrowRightClicked: sequences`competition.arrowRightClicked`,
};

function Presentation({
  className,
  image,
  competitionPresentationClicked,
  arrowLeftClicked,
  arrowRightClicked,
}: Props & typeof deps & ConnectedProps): React.ReactElement {
  function handleClick(e: KeyboardEvent) {
    switch (e.key) {
      case 'ArrowLeft':
        arrowLeftClicked();
        break;
      case 'ArrowRight':
        arrowRightClicked();
        break;
      case 'Escape':
        competitionPresentationClicked();
        break;
      default:
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleClick);

    return () => {
      document.removeEventListener('keydown', handleClick);
    };
  });

  const t = useText();

  return (
    <Wrapper className={className}>
      <Icon
        icon={faTimesCircle}
        title={t('competition.endPresentation') as string}
        onClick={() => competitionPresentationClicked()}
      />
      {image ? (
        <>
          <HeaderWrapper>
            <Arrow
              onClick={() => arrowLeftClicked()}
              icon={faArrowCircleLeft}
              disabled={image.index === 0}
            />
            <TitleWrapper>
              <Title>{image.title}</Title>
            </TitleWrapper>
            <Arrow
              onClick={() => arrowRightClicked()}
              icon={faArrowCircleRight}
              disabled={image.index + 1 === image.imageCount}
            />
          </HeaderWrapper>
          <Image path={image.imageUrl} onClick={() => arrowRightClicked()} />
          <ImageCounter>
            {`${image.index + 1} / ${image.imageCount}`}
          </ImageCounter>
        </>
      ) : null}
    </Wrapper>
  );
}

export default connect<Props, typeof deps>(deps, Presentation);
