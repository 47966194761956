import styled from 'styled-components';

import EventComponent from './Event';

export const Wrapper = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: column;
`;

export const Loading = styled.h2`
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
`;
export const Link = styled.a`
  text-decoration: none;
  color: #ee2642;
  font-weight: bold;
`;

export const Title = styled.h2`
  margin: 0;
  padding: 0;
  text-align: left;
`;

export const Event = styled(EventComponent)``;
