import React from 'react';
import { connect, ConnectedProps } from '@cerebral/react';
import { state } from 'client/app.cerebral';

import { Wrapper, Loading, Event } from './elements';

import { useText } from 'common/hooks';

type Props = {
  className?: string;
};

const deps = {
  eventsList: state.events.eventsList,
  loadingEvents: state.events.loadingEvents,
};

function Competition({
  className,
  eventsList,
  loadingEvents,
}: Props & typeof deps & ConnectedProps): React.ReactElement {
  const t = useText();

  if (loadingEvents) {
    return (
      <Wrapper className={className}>
        <Loading>{t('events.loadingEvents')}</Loading>
      </Wrapper>
    );
  }
  if (eventsList.length === 0) {
    return (
      <Wrapper className={className}>
        <Loading>{t('events.noUpcomingEvents')}</Loading>
      </Wrapper>
    );
  }

  return (
    <Wrapper className={className}>
      {eventsList.map((event) => (
        <Event key={event.id} event={event} />
      ))}
    </Wrapper>
  );
}

export default connect(deps, Competition);
