import React from 'react';
import { connect, ConnectedProps } from '@cerebral/react';
import { state, sequences, props } from 'client/app.cerebral';
import { faCircleNotch, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import {
  IconAnimated,
  IconClickable,
  Buttons,
  Button,
  ModalText,
  Modal,
} from '../elements';

type Props = {
  uid: string;
};

const deps = {
  profile: state.profiles[props`uid`],
  willSendMail: state.members.willSendMail[props`uid`],
  isSendingMail: state.members.isSendingMail[props`uid`],
  setWillSendMail: sequences`members.setWillSendMail`,
  sendInfoEmail: sequences`members.sendInfoEmail`,
  sendWelcomeEmail: sequences`members.sendWelcomeEmail`,
};

function Mails({
  uid,
  isSendingMail,
  profile,
  willSendMail,
  sendInfoEmail,
  sendWelcomeEmail,
  setWillSendMail,
}: Props & typeof deps & ConnectedProps): React.ReactElement {
  return (
    <>
      {willSendMail?.welcome ? (
        <Modal id={`willSendWelcome${uid}`}>
          <ModalText>{`Do you want to send a welcome email to ${profile?.name}`}</ModalText>
          <Buttons>
            <Button onClick={() => sendWelcomeEmail({ uid })}>{'Yes'}</Button>
            <Button
              onClick={() => setWillSendMail({ mails: {}, uid })}
              color={'red'}
            >
              {'No'}
            </Button>
          </Buttons>
        </Modal>
      ) : null}
      {willSendMail?.info ? (
        <Modal id={`willSendInfo${uid}`}>
          <ModalText>{`Do you want to send an info email to ${profile?.name}`}</ModalText>
          <Buttons>
            <Button onClick={() => sendInfoEmail({ uid })}>{'Yes'}</Button>
            <Button
              onClick={() => setWillSendMail({ mails: {}, uid })}
              color={'red'}
            >
              {'No'}
            </Button>
          </Buttons>
        </Modal>
      ) : null}
      {isSendingMail?.welcome ? (
        <IconAnimated icon={faCircleNotch} />
      ) : (
        <IconClickable
          title={'Send welcome email'}
          color={profile?.mails?.welcome ? 'green' : 'red'}
          onClick={() => setWillSendMail({ mails: { welcome: true }, uid })}
          icon={faEnvelope}
        />
      )}
      {isSendingMail?.info ? (
        <IconAnimated icon={faCircleNotch} />
      ) : (
        <IconClickable
          title={'Send information email'}
          color={profile?.mails?.info ? 'green' : 'red'}
          onClick={() => setWillSendMail({ mails: { info: true }, uid })}
          icon={faEnvelope}
        />
      )}
    </>
  );
}

export default connect<Props, typeof deps>(deps, Mails);
