/* eslint-disable max-classes-per-file */
import { CerebralError } from 'cerebral';

export class FirebaseProviderError extends CerebralError {
  private readonly message: string;

  private readonly code: string | undefined;

  constructor(error: Error & { code?: string }) {
    super(error.message);
    this.name = 'FirebaseProviderError';
    this.message = error.message;
    this.code = error.code;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  toJSON() {
    return {
      name: this.name,
      message: this.message,
      code: this.code,
    };
  }
}

export class FirebaseProviderAuthenticationError extends CerebralError {
  private readonly message: string;

  private readonly code: string | undefined;

  constructor(error: Error & { code?: string }) {
    super(error.message);
    this.name = 'FirebaseProviderAuthenticationError';
    this.message = error.message;
    this.code = error.code;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  toJSON() {
    return {
      name: this.name,
      message: this.message,
      code: this.code,
    };
  }
}
