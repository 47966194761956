import { sequence, parallel } from 'cerebral';
import { state, props } from 'client/app.cerebral';
import { set, unset, when, toggle } from 'cerebral/factories';

import * as actions from './actions';
import { getAllProfiles } from 'modules/Members/actions';

export const getCompetitions = [
  actions.getCompetitions,
  {
    error: [],
    success: [set(state.competition.list, props`competitions`)],
  },
  getAllProfiles,
  {
    success: [set(state.profiles, props`profiles`)],
    error: set(state.members.error, 'getMembersFailed'),
  },
];

export const getCompetition = [
  actions.getCompetition,
  {
    error: [],
    success: [
      set(
        state.competition.competitions[props`competitionKey`],
        props`competition`
      ),
      actions.getProfiles,
    ],
  },
];

export const competitionsRouted = [
  when(state.app.user),
  { true: getCompetitions, false: [] },
];

export const competitionRouted = [
  when(state.app.user),
  {
    true: [
      set(state.competition.currentCompetition, props`competitionKey`),
      when(state.competition.list[props`competitionKey`]),
      {
        true: getCompetition,
        false: parallel([getCompetitions, getCompetition]),
      },
      actions.listenForVisibilityChange,
      actions.listenForImageAdded,
      actions.listenForImageChanged,
      actions.listenForImageRemoved,
    ],
    false: [],
  },
];

export const removeCompetitionConfirmClicked = sequence(
  'remove competition confirmed clicked',
  [
    actions.removeCompetition,
    {
      error: [],
      success: [
        unset(state.competition.list[state.app.modal.payload.competitionKey]),
        set(state.app.modal.type, null),
        set(state.app.modal.sequences, null),
        set(state.app.modal.payload, {}),
      ],
    },
  ]
);

export const editCompetitionRouted = [
  set(state.competition.currentCompetition, props`competitionKey`),
  when(state.competition.list[props`competitionKey`]),
  {
    true: getCompetition,
    false: parallel([getCompetitions, getCompetition]),
  },
];

export const removeCompetitionClicked = [
  set(state.app.modal.payload.competitionKey, props`competitionKey`),
  set(state.app.modal.sequences, 'competition.removeCompetitionConfirmClicked'),
  set(state.app.modal.type, 'removeCompetition'),
];

export const removeImageConfirmClicked = sequence(
  'remove image confirmed clicked',
  [
    actions.removeImage,
    {
      error: [],
      success: [
        set(state.app.modal.type, null),
        set(state.app.modal.sequences, null),
        set(state.app.modal.payload, {}),
      ],
    },
  ]
);

export const removeImageClicked = [
  set(state.app.modal.payload.imageKey, props`imageKey`),
  set(state.app.modal.payload.competitionKey, props`competitionKey`),
  set(state.app.modal.sequences, 'competition.removeImageConfirmClicked'),
  set(state.app.modal.type, 'removeImage'),
];

export const toggleCompetitionVisibility = [
  actions.toggleCompetitionVisibility,
];

export const competitionVisibilityChanged = [
  set(state.competition.list[props`competitionKey`].isVisible, props`value`),
];

export const setBadge = [actions.setBadge];

export const addImage = [
  set(
    state.competition.competitions[props`value.competitionKey`].images[
      props`key`
    ],
    props`value`
  ),
];

export const changeImage = [
  set(
    state.competition.competitions[props`value.competitionKey`].images[
      props`key`
    ],
    props`value`
  ),
];

export const removeImage = [
  unset(
    state.competition.competitions[props`competitionKey`].images[props`key`]
  ),
];

export const toggleCompetitionPresentation = [
  toggle(state.competition.isPresentation),
  set(state.competition.slideIndex, 0),
];

export const nextSlide = [actions.incrementSlide];

export const previousSlide = [actions.decrementSlide];
