import { changePage } from './factories';
import { authenticate } from '../Auth/factories';
import computeIsActiveMember from 'computed/computeIsActiveMember';
import computeProfile from 'computed/computeProfile';
import computeIsAdmin from 'computed/computeIsAdmin';
import computeUid from 'computed/computeUid';

import {
  competitionsRouted,
  competitionRouted,
  editCompetitionRouted,
} from 'modules/Competition/sequences';

import { eventsRouted } from 'modules/Events/sequences';
import { profileRouted } from 'modules/Profile/sequences';
import { membersRouted } from 'modules/Members/sequences';

import { OwnState } from './types';

import * as sequences from './sequences';

const state: OwnState = {
  page: 'frontPage',
  overlayImagePath: '',
  menuExpanded: false,
  modal: {
    type: null,
    payload: {},
    sequences: null,
  },
  currentModal: null,
  user: null,
  uid: computeUid,
  profile: computeProfile,
  isAdmin: computeIsAdmin,
  isActiveMember: computeIsActiveMember,
  message: {
    name: {
      value: '',
      defaultValue: '',
      isRequired: false,
      isValid: true,
    },
    email: {
      value: '',
      defaultValue: '',
      isRequired: false,
      isEmail: true,
      isValid: true,
    },
    message: {
      value: '',
      defaultValue: '',
      isRequired: true,
      isValid: false,
    },
  },
};

export default {
  state,
  sequences: {
    frontPageNavigated: changePage(
      authenticate([eventsRouted, competitionsRouted])
    ),
    joinUsNavigated: changePage(authenticate()),
    eventsNavigated: changePage(authenticate(eventsRouted)),
    profileNavigated: changePage(authenticate(profileRouted)),
    membersNavigated: changePage(authenticate(membersRouted)),
    competitionsNavigated: changePage(authenticate(competitionsRouted)),
    competitionNavigated: changePage(authenticate(competitionRouted)),
    editCompetitionNavigated: changePage(authenticate(editCompetitionRouted)),
    newCompetitionNavigated: changePage(authenticate()),
    newImageNavigated: changePage(authenticate(competitionRouted)),
    aboutNavigated: changePage(authenticate()),
    kronikenNavigated: changePage(authenticate()),
    bookingNavigated: changePage(authenticate()),
    notFoundNavigated: changePage(authenticate()),
    imageClicked: sequences.imageClicked,
    setLanguage: sequences.setLanguage,
    goTo: sequences.goTo,
    addUrl: sequences.addUrl,
    resetModal: sequences.resetModal,
    openWithToken: sequences.openWithToken,
    toggleMenu: sequences.toggleMenu,
    termsAccepted: sequences.termsAccepted,
    setCurrentModal: sequences.setCurrentModal,
    sendMessage: sequences.sendMessage,
  },
  services: {},
};
