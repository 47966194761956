import { Compute } from 'cerebral';
import { state } from 'client/app.cerebral';

export default Compute((get) => {
  const list = get(
    state.competition.competitions[state.competition.currentCompetition].images
  );

  return {
    imageCount: Object.keys(list || {}).length,
    participantCount: new Set(Object.values(list || {}).map((img) => img.uid))
      .size,
  };
});
