import firstParagraph from './firstParagraph';
import thirdStep from './thirdStep';

export default {
  title: 'Join Us!',
  memberFee: 'Membership Fee',
  firstParagraph,
  form: {
    title: 'Send us a mail!',
    name: 'Name',
    email: 'E-mail',
    message: 'Message',
    subject: 'Subject',
    submit: 'Send',
  },
  steps: 'To Register As a Member in Studentenes Kameraklubb:',
  firstStepTitle: '1. Create a user and log in',
  firstStep:
    'Click on the "Log In"-button in the navigation menu at the top of the page. Sign in with your Google account.',
  secondStepTitle: '2. Recieve a Welcome Email',
  secondStep: 'You will recieve a welcome email with further instructions.',
  thirdStepTitle: '3. Pay the Membership Fee',
  thirdStep,
};
