import {
  signInWithPopup,
  FacebookAuthProvider,
  type Auth,
} from 'firebase/auth';
import { createUser } from './helpers';
import { FirebaseProviderAuthenticationError } from './errors';

export default function signInWithFacebook(auth: Auth) {
  const provider = new FacebookAuthProvider();

  return signInWithPopup(auth, provider)
    .then((result) => {
      return { user: createUser(result.user) };
    })
    .catch((error) => {
      throw new FirebaseProviderAuthenticationError(error);
    });
}
