import React from 'react';
import { state, sequences } from 'client/app.cerebral';
import { connect, ConnectedProps } from '@cerebral/react';

import { useText } from 'common/hooks';

import { Wrapper, Filter, Input, Text } from './elements';

type Props = {
  className?: string;
};

const deps = {
  filters: state.members.filters,
  setFilter: sequences`members.setFilter`,
};

function Filters({
  className,
  filters,
  setFilter,
}: Props & typeof deps & ConnectedProps): React.ReactElement {
  const t = useText();

  return (
    <Wrapper className={className}>
      {(Object.keys(filters || {}) as (keyof typeof filters)[]).map(
        (filter) => (
          <Filter key={filter}>
            <Input
              type={'checkbox'}
              checked={filters[filter]}
              onChange={() => setFilter({ filter })}
            />
            <Text>{t(`members.filters.${filter}`)}</Text>
          </Filter>
        )
      )}
    </Wrapper>
  );
}

export default connect<Props, typeof deps>(deps, Filters);
