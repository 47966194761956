import React from 'react';

import { useText } from 'common/hooks';
import { connect, ConnectedProps } from '@cerebral/react';
import { state } from 'client/app.cerebral';
import { Header, Wrapper, NotLoggedIn } from './elements';

const deps = {
  user: state.app.user,
};

function Booking({ user }: typeof deps & ConnectedProps): React.ReactElement {
  const t = useText();

  if (!user) {
    return (
      <Wrapper>
        <NotLoggedIn>{t('common.notLoggedInBooking')}</NotLoggedIn>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Header>{'Booking'}</Header>
      {/* We need to allow same-origin for the iframe to work. Not really a security risk*/
      /* eslint-disable-next-line react/iframe-missing-sandbox*/}
      <iframe
        src="https://teamup.com/ksisbakm8w2a93sakb?showHeader=0&showLogo=1&showSearch=0&showProfileAndInfo=0&showSidepanel=0&disableSidepanel=0&showTitle=0&showViewSelector=0&showMenu=0&showViewHeader=1&showAgendaDetails=0&showDateControls=1"
        style={{ width: '100%', height: '80vh', border: '1px solid #cccccc' }}
        loading="lazy"
        sandbox="allow-forms allow-scripts allow-same-origin"
      />
    </Wrapper>
  );
}

export default connect(deps, Booking);
