import { Compute } from 'cerebral';
import { state } from 'client/app.cerebral';

export default Compute((get) => {
  const user = get(state.app.user);

  if (!user) return null;

  return user.uid || null;
});
