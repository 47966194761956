import {
  ref as dbRef,
  query,
  orderByValue,
  orderByKey,
  orderByChild,
  startAt,
  endAt,
  off,
  onValue,
  onChildAdded,
  onChildChanged,
  onChildRemoved,
  type Database,
  type Query,
} from 'firebase/database';
import { type User } from 'firebase/auth';
import { ref as storageRef, type FirebaseStorage } from 'firebase/storage';
import { FirebaseProviderError } from './errors';

const refs: Record<string, Record<string, Query[]>> = {};

type Options = {
  orderByKey?: boolean;
  orderByChild?: string;
  orderByValue?: boolean;
  startAt?: number;
  endAt?: number;
};

export function createRef(
  database: Database,
  rawPath: string,
  options: Options = {}
) {
  if (rawPath.indexOf('/') >= 0) {
    throw new FirebaseProviderError(
      new Error(
        `The path "${rawPath}" is not valid. Use dot notation for consistency with Cerebral`
      )
    );
  }

  const path = rawPath.replace(/\./gu, '/');

  return (Object.keys(options) as (keyof typeof options)[]).reduce((q, key) => {
    switch (key) {
      case 'orderByKey': {
        const value = options[key];

        return value ? query(q, orderByKey()) : q;
      }
      case 'orderByChild': {
        const value = options[key];

        return value ? query(q, orderByChild(value)) : q;
      }
      case 'orderByValue': {
        const value = options[key];

        return value ? query(q, orderByValue()) : q;
      }
      case 'startAt': {
        const value = options[key];

        return query(q, startAt(value));
      }
      case 'endAt': {
        const value = options[key];

        return value ? query(q, endAt(value)) : q;
      }
      default: {
        return q;
      }
    }
  }, query(dbRef(database, path)));
}

export function createStorageRef(storage: FirebaseStorage, path: string) {
  return storageRef(storage, path);
}

const listenEvents = {
  value: onValue,
  child_added: onChildAdded,
  child_changed: onChildChanged,
  child_removed: onChildRemoved,
};

export function listenTo(
  ref: Query,
  path: string,
  event: keyof typeof listenEvents,
  signal: string,
  cb: (data: any) => void
) {
  refs[path] ||= {};
  refs[path][event] = refs[path][event] ? refs[path][event].concat(ref) : [ref];

  listenEvents[event](ref, cb, (error) => {
    throw new FirebaseProviderError(
      new Error(
        `${event} listener to path ${path}, triggering signal: ${signal}, gave error: ${error.message}`
      )
    );
  });
}

const events = {
  onChildAdded: 'child_added',
  onChildChanged: 'child_changed',
  onChildRemoved: 'child_removed',
  onValue: 'value',
  '*': '*',
};

export function stopListening(passedPath: string, event: keyof typeof events) {
  const realEventName = events[event] || '*';
  const pathArray = passedPath.split('.');
  let path = passedPath;
  let isWildcardPath = false;

  if (event && !realEventName) {
    throw new FirebaseProviderError(
      new Error(
        `The event "${event}" is not a valid event. Use: "${Object.keys(
          events
        )}`
      )
    );
  }

  if (pathArray[pathArray.length - 1] === '*') {
    isWildcardPath = true;
    pathArray.pop();
    path = pathArray.join('.');
  }

  let refsHit = [];

  if (isWildcardPath) {
    refsHit = Object.keys(refs).reduce<string[]>((currentKeysHit, refPath) => {
      if (refPath.indexOf(path) === 0) {
        return currentKeysHit.concat(refPath);
      }

      return currentKeysHit;
    }, []);
  } else {
    refsHit = refs[path] ? [path] : [];
  }

  if (!refsHit.length) {
    throw new FirebaseProviderError(
      new Error(`The path "${path}" has no listeners`)
    );
  }

  refsHit.forEach((refPath) => {
    const ref = refs[refPath];

    if (realEventName === '*') {
      Object.keys(ref).forEach((eventName) => {
        ref[eventName].forEach((listener) => off(listener));
        delete ref[eventName];
      });
    } else {
      if (!ref[realEventName]) {
        throw new FirebaseProviderError(
          new Error(`The path"${path}" has no listeners for "${realEventName}"`)
        );
      }
      ref[realEventName].forEach((listener) => off(listener));
      delete ref[realEventName];
    }

    if (Object.keys(ref).length === 0) {
      delete refs[refPath];
    }
  });
}

export function createUser(user: User) {
  return {
    uid: user.uid,
    isAnonymous: user.isAnonymous,
    providerData: user.providerData,
    displayName: user.displayName,
    email: user.email,
    emailVerified: user.emailVerified,
    photoURL: user.providerData.some((d) => d.providerId === 'facebook.com') // We don't want facebook images, as they come with tracking
      ? null
      : user.photoURL,
  };
}

export function noop() {
  // Noop
}
