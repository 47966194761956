import styled from 'styled-components';
export const Title = styled.h1`
  color: black;
  font-weight: bolder;
  text-align: center;
  @media (max-width: 1024px) {
    font-size: 1.6rem;
  }
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

export const Logo = styled.img`
  display: block;
  width: 10rem;
  height: auto;
  margin: 0 auto;
  @media (max-width: 1024px) {
    width: 8rem;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 100%;
  height: fit-content;
`;

export const MenuItem = styled.a`
  display: block;
  text-decoration: none;
  padding: 0.8rem;
  font-size: 1.5rem;
  color: black;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  border-radius: 100em;
  &:hover {
    background-color: #ee264220;
  }
  &:active {
    background-color: #ee264240;
  }
`;
