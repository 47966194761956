import { Compute } from 'cerebral';
import { state } from 'client/app.cerebral';

export default Compute((get) => {
  const list = get(state.competition.list);

  return Object.keys(list || {})
    .filter((compKey) => list[compKey].toDatetime < Date.now())
    .sort((a, b) => list[b].createdDatetime - list[a].createdDatetime);
});
