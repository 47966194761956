import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Wrapper = styled.div``;

type TitleProps = {
  completed?: boolean;
};

export const Title = styled.h3<TitleProps>`
  color: ${(props) => (props.completed ? 'green' : 'black')};
`;

export const Description = styled.span``;

export const Icon = styled(FontAwesomeIcon)`
  fill: ${(props) => props.color};
  margin-right: 10px;
  max-height: 22.5px;
`;
