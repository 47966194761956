import React from 'react';

import { Wrapper } from './elements';

type Props = {
  className?: string;
  children: React.ReactNode;
};

export default function ImageWrapper({
  className,
  children,
}: Props): React.ReactElement {
  return <Wrapper className={className}>{children}</Wrapper>;
}
