import { useContext } from 'react';

import LanguageContext from 'client/LanguageContext';
import { format, parseISO, isValid } from 'date-fns';
import en from 'date-fns/locale/en-US';
import no from 'date-fns/locale/nb';

const locales = {
  en,
  no,
};

export default function useFormatDate() {
  const locale = useContext(LanguageContext) || 'no';

  return (date: number | string, formatString: string) => {
    if (typeof date === 'number')
      return format(date, formatString, {
        locale: locales[locale],
      });
    if (!isValid(parseISO(date))) return '';

    return format(parseISO(date), formatString, {
      locale: locales[locale],
    });
  };
}
