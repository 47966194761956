import { set, when } from 'cerebral/factories';
import { sequence } from 'cerebral';
import { state, props, Context } from 'client/app.cerebral';

export function changePage(continueChain: any[] = []) {
  return sequence('change page', [
    set(state.app.menuExpanded, false),
    set(state.page, props`name`),
    set(state.competition.isPresentation, false),
    when(props`competitionKey`),
    {
      true: set(state.competition.currentCompetition, props`competitionKey`),
      false: [],
    },
    ...continueChain,
  ]);
}

export function goTo(url: string) {
  return function goToAction({ router, get }: Context) {
    return router.goTo(get(url));
  };
}
