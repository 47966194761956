import styled from 'styled-components';
import DragScroll from 'react-dragscroll';

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  gap: 1em;
  flex-shrink: 0;
`;

export const Image = styled.img`
  width: auto;
  height: 100%;
`;

export const Scroll = styled(DragScroll)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  gap: 1em;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
