import { listenTo, createRef } from './helpers';
import { Database } from 'firebase/database';

export default function onChildChangedFactory(database: Database) {
  return function onChildChangedFunction(
    this: any,
    path: string,
    signal: string,
    options: Parameters<typeof createRef>[2] & {
      payload?: Record<string, any>;
    } = {}
  ) {
    listenTo(
      createRef(database, path, options),
      path,
      'child_changed',
      signal,
      (data) => {
        this.context.app.getSequence(signal)({
          key: data.key,
          value: data.val(),
          ...options.payload,
        });
      }
    );
  };
}
