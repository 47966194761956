import React from 'react';
import { connect, ConnectedProps } from '@cerebral/react';
import { state, sequences } from 'client/app.cerebral';

import { useText } from 'common/hooks';
import { Wrapper, Button } from './elements';

type Props = {
  className?: string;
};

const deps = {
  isAdmin: state.app.isAdmin,
  updateEventsClicked: sequences`events.updateEventsClicked`,
};

function Admin({
  className,
  isAdmin,
  updateEventsClicked,
}: Props & typeof deps & ConnectedProps): React.ReactNode {
  if (!isAdmin) return null;

  const t = useText();

  return (
    <Wrapper className={className}>
      <Button onClick={() => updateEventsClicked()}>
        {t('events.updateEvents')}
      </Button>
    </Wrapper>
  );
}

export default connect<Props, typeof deps>(deps, Admin);
