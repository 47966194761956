import common from './common';
import frontPage from './frontPage';
import joinUs from './joinUs';
import contact from './contact';
import footer from './footer';
import menu from './menu';
import images from './images';
import modals from './modals';
import errors from './errors';
import competition from './competition';
import events from './events';
import members from './members';
import profile from './profile';
import terms from './terms';
import about from './about';
import kroniken from './kroniken';
import notfound from './notfound';
export default {
  locale: 'en',
  phrases: {
    common,
    frontPage,
    joinUs,
    contact,
    footer,
    menu,
    images,
    modals,
    errors,
    competition,
    events,
    members,
    profile,
    terms,
    about,
    kroniken,
    notfound,
  },
};
