export default {
  title: 'Krøniken',
  firstParagraph: `MARKDOWN: *Krøniken* er Studentenes Kameraklubbs eget fotomagasin. Hvert år samler *Krøniken*-redaksjonen de beste bildene fra klubbens medlemmer for å publisere dem i et vakkert magasin.`,
  subHeader: 'Send inn bilder for 4. utgave: Lys/skygge',
  secondParagraph: `MARKDOWN:
  Temaet for 4. utgave av Krøniken er klart! \n
  Tenk bredt, her er det mange muligheter og tolkninger man kan ta.\n

  Lys og skygge går hånd i hånd og er noen av de viktigste elementene i fotografi. Lys illuminerer motivet, og sammen med skygge lager den dybde, tekstur og stemning. Den kan både være naturlig og kunstig. Skygger kan komme i mange former, og måten man bruker dem på kan både skape drama, eller mykhet. Man må forstå lys for å få de skyggene man ønsker.
  
  Last opp bilder med lenken under, i en egen mappe du lager selv med navnet ditt som tittel. For å få med kamera, film og evt bildenavn er det nice hvis dere navngir hvert bilde i formatet:
  
  “NavnKameraFilmNavnPåBilde”

  **Send inn bilder innen: 16. september**`,
  join: 'Meld deg på her!',
  submitImages: 'Send inn bilder her!',
  oldEditions: 'Tidligere utgaver',
  thirdParagraph: `MARKDOWN: Ønsker du å bla gjennom eldre utgaver av *Krøniken*? Noen av dem er digitalt arkivert:`,
  thirdEdition: '3. utgave: Studentlivet nå, og for 85 år siden',
  secondEdition: '2. utgave',
  buy: `MARKDOWN: Ønsker du å skaffe deg en kopi av de tidligere utgavene? Besøk oss i kjelleren eller send oss en melding. Prisen på 3.utgave er 50 kr for ikke-medlemmer og medlemmer som ønsker å kjøpe eksemplar nr 2. `,
};
