export default `MARKDOWN:
  Studentenes Kameraklubb er et samlepunkt for fotointeresserte studenter i
  Trondheim. Klubben har en tradisjonsrik historie som har røtter helt tilbake
  til 1938. Våre fasiliteter / møtested befinner seg på Moholt Studentby, hvor
  vi tilbyr tilgang til alle våre medlemmer. Der kan du slenge deg på sofaen og
  ta en liten prat med andre fotointeresserte eller
  redigere bilder på datarommet. Vi er også veldig stolte av vårt eget mørkerom
  fylt med alt det nødvendige utstyret for deg som vil prøve å fremkalle bilder
  med de litt eldre kameraene.
`;
