import React from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { Wrapper, AddIcon } from './elements';

type Props = {
  className?: string;
  link: string;
  disabled?: boolean;
};

function NewCard({ className, link, disabled }: Props): React.ReactElement {
  return (
    // eslint-disable-next-line
    <a {...(!disabled ? { href: link } : {})}>
      <Wrapper className={className}>
        <AddIcon icon={faPlus} disabled={disabled} />
      </Wrapper>
    </a>
  );
}

export default NewCard;
