export default {
  title: 'About us',
  firstParagraph: `Welcome to Studentenes Kameraklubb! We're a collective of studentens in 
    Trondheim who share a unified passion for photography. In our view, engaging 
    with fellow photographers is an excellent means to inspire and nurture one's 
    creativity. We therefore encourage you not to confine your photographic 
    pursuits to your home, but rather to share your enthusiasm with like-minded 
    individuals. Regardless of your preferred medium - be it analog, digital, 
    vintage equipment, or the latest smartphone - our club embraces all 
    enthusiasts. Whether your interests lie in mastering analog film development, 
    garnering appreciation on social media, or simply sharing your artistic 
    creations, we invite you to become a member of our club.`,
  history: 'Our history',
  secondParagraph: `The club was officially founded on the 3rd of May, 1938. Before then SK was a part of Akademisk Radioklubb (ARK).
   ARK had their own darkroom at Samfundet, and when SK broke out they applied to take over these rooms. They also applied to become 
   the official photographers of Samfundet and UKA, but they were not allowed to do so until UKA-47. SK established a working group 
   specifically for photographing for Samfundet, what we today know as Fotogjengen. At that time SK borrowed a darkroom at Singsaker Studenthjem.
    In 1958 Fotogjengen was incorporated as an official group at Samfundet, and this probably also marks when SK was separated.
    Members who did not want to photograph for Samfundet, but rather just for fun, remained in SK. We know little about the club's operations
    in the years after, but we are working to find out more.

    In 2016, the camera club moved from the old premises in Herman Krags vei to the current premises in Prestekragevegen. In 2018, the club turned 80 years old.
    For the occasion, the club hosted an exhibition at realfagsbiblioteket at Gløshaugen, where the club's history was presented. Here they displayed photos taken by 
    the club's members, as well as photos from the club's 80-year old archive`,
  linkFG: 'Much of this information was gathered from Fotogjengen.',
  board: 'The board',
  leder: 'President',
  nestleder: 'Vice President',
  okonomi: 'Treasurer',
  kjeller: 'Darkroom Manager',
  kroniken: 'Krøniken Editor',
  arrangementer: 'Events Committee',
  itkom: 'IT Committee',
  statutes: 'Statutes',
  statutesLink: `Click here to see our statutes`,
};
