import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ImageComponent from 'common/Image';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 370px;
  max-height: 400px;
  height: fit-content;
  background-color: black;
  color: white;
  border-radius: 5px;
  width: 100%;
  margin: 10px;
`;
export const Image = styled(ImageComponent)`
  width: 360px;
  height: 280px;
  align-self: center;
  margin: auto;
  background-size: cover;
  max-width: 100%;
`;

export const Description = styled.p`
  height: fit-content;
  margin: 0.5rem;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px;
`;

export const Remove = styled(FontAwesomeIcon)`
  font-size: 32px;
  cursor: pointer;
  &:hover {
    & > path {
      transition-duration: 0.2s;
      fill: red;
    }
  }
`;

export const Header = styled.h3`
  font-size: 20px;
  line-height: 24px;
  margin: 6px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: white;
  font-weight: 900;
  max-width: 310px;
  @media (max-width: 768px) {
    max-width: 256px;
  }
`;

export const Link = styled.a`
  color: inherit;
  text-decoration: inherit;
  width: fit-content;
  max-width: 100%;
  align-self: center;
`;
