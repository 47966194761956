import React from 'react';

import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

import {
  Wrapper,
  Timeline,
  DateLabel,
  TimeLabel,
  Spacer,
  Info,
  Title,
  Location,
  Line,
  Description,
  TimeWrapper,
  Marker,
  LocationWrapper,
  EventLink,
  Link,
} from './elements';

import { useFormatDate, useText } from 'common/hooks';
import { FormattedEvent } from 'modules/Events/types';

type Props = {
  event: FormattedEvent;
};

export default function Event({ event }: Props): React.ReactElement {
  const formatDate = useFormatDate();
  const t = useText();

  const facebookRegex =
    /https?:\/\/?www\.?facebook\.com\/events\/\d+\/?(?:\?event_time_id=\d+)?/u;
  const eventLinkMatch = event.description.match(facebookRegex);
  const eventLink = eventLinkMatch ? eventLinkMatch[0] : null;
  const description = event.description.replace(facebookRegex, '');

  const linkRegex = /(?<url>https?:\/\/[^\s]+)/gu;
  const descriptionElement = description.split(linkRegex).map((part, index) => {
    if (part.match(linkRegex)) {
      return (
        <Link key={index} href={part}>
          {part}
        </Link>
      );
    }

    return <span key={index}>{part}</span>;
  });

  return (
    <Wrapper>
      <Timeline>
        <DateLabel>{formatDate(event.fromDatetime, 'do MMM')}</DateLabel>
        <TimeWrapper>
          {event.hasTime ? (
            <TimeLabel>{formatDate(event.fromDatetime, 'HH:mm')}</TimeLabel>
          ) : null}
          {!event.isSameDay || event.hasTime ? <Spacer /> : null}
          {!event.isSameDay && (
            <DateLabel>{formatDate(event.toDatetime, 'do MMM')}</DateLabel>
          )}
          {event.hasTime ? (
            <TimeLabel>{formatDate(event.toDatetime, 'HH:mm')}</TimeLabel>
          ) : null}
        </TimeWrapper>
      </Timeline>
      <Info>
        <Title>{event.title}</Title>
        <LocationWrapper>
          <Marker icon={faMapMarkerAlt} />
          <Location>{event.location || 'Lokasjon kommer'}</Location>
        </LocationWrapper>
        <Line />
        <Description>{descriptionElement || 'Info kommer'}</Description>
        {eventLink ? (
          <EventLink href={eventLink}> {t('events.facebookLink')}</EventLink>
        ) : null}
      </Info>
    </Wrapper>
  );
}
