import styled from 'styled-components';
import SubmitButton from 'common/SubmitButton';

export const Wrapper = styled.div`
  max-width: 400px;
  margin: 0 auto;
  padding: 20px 0;
`;

export const Header = styled.h2`
  text-align: center;
`;

export const Button = styled(SubmitButton)``;

export const Error = styled.p`
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
`;
