import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import { connect } from '@cerebral/react';
import { state, sequences } from 'client/app.cerebral';

import { Wrapper, Black } from './elements';

type Props = {
  className?: string;
  id: string;
  children: React.ReactNode;
};

const deps = {
  currentModal: state.app.currentModal,
  setCurrentModal: sequences`app.setCurrentModal`,
};

class Modal extends Component<Props & typeof deps> {
  componentDidMount() {
    if (!this.props.currentModal) {
      this.props.setCurrentModal({ modal: this.props.id });
    }
  }

  componentDidUpdate() {
    if (!this.props.currentModal) {
      this.props.setCurrentModal({ modal: this.props.id });
    }
  }

  componentWillUnmount() {
    if (this.props.currentModal === this.props.id) {
      this.props.setCurrentModal({ modal: null });
    }
  }

  render() {
    return (
      this.props.currentModal === this.props.id &&
      createPortal(
        <Black>
          <Wrapper className={this.props.className}>
            {this.props.children}
          </Wrapper>
        </Black>,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        document.querySelector('body')
      )
    );
  }
}

export default connect<Props, typeof deps>(deps, Modal);
