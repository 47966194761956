import React from 'react';
import Markdown from 'markdown-to-jsx';
import Polyglot from 'node-polyglot';
import languages from 'resources/languages';

export default function createTextApi({
  locale = 'no',
}: {
  locale: 'en' | 'no';
}) {
  const polyglot = new Polyglot({
    phrases: languages[locale || 'no'].phrases,
    locale: languages[locale || 'no'].locale,
  });

  const t = function t(...args: Parameters<typeof polyglot.t>) {
    const text = polyglot.t(...args);

    if (text.substring(0, 9) === 'MARKDOWN:') {
      return <Markdown>{text.replace('MARKDOWN:', '')}</Markdown>;
    }

    return text;
  };

  return t;
}
