export default {
  newCompetition: {
    title: 'Nytt album',
    form: {
      title: 'Tittel',
      description: 'Beskrivelse',
      from: 'Fra',
      to: 'Til',
      coverImage: 'Forsidebilde',
      maxParticipants: 'Max antall bilder per person',
      isVisible: 'Bilder er synlig for alle',
      submit: 'Lag nytt album',
      creating: 'Lager nytt album...',
    },
  },
  editCompetition: {
    title: 'Rediger galleri',
    form: {
      submit: 'Oppdater galleri',
      saving: 'Lagrer...',
    },
  },
  newImage: {
    title: 'Nytt bilde',
    form: {
      title: 'Tittel',
      image: 'Bilde',
      submit: 'Legg til nytt bilde',
      uploading: 'Laster opp...',
    },
  },
  startPresentation: 'Start presentasjon',
  startPresentationShort: 'Presentasjon',
  endPresentation: 'Avslutt presentasjon',
  edit: 'Rediger galleri',
  editShort: 'Rediger',
  showImages: 'Vis alle bildene til alle',
  showImagesShort: 'Vis bilder',
  downloadImages: 'Last ned alle bildene',
  downloadImagesShort: 'Last ned',
};
