import { Compute } from 'cerebral';
import { state } from 'client/app.cerebral';
import { isBefore, isSameDay, differenceInDays } from 'date-fns';

export default Compute((get) => {
  const list = get(state.events.list);

  return Object.keys(list || {})
    .map((eventID) => ({
      id: eventID,
      fromDatetime: list[eventID].start.dateTime,
      toDatetime: list[eventID].end.dateTime,
      hasTime: Boolean(list[eventID].start.dateTime),
      location: list[eventID].location,
      title: list[eventID].summary,
      description: list[eventID].description || '',
    }))
    .map((object) => ({
      isSameDay:
        isSameDay(new Date(object.fromDatetime), new Date(object.toDatetime)) ||
        (!object.hasTime &&
          differenceInDays(
            new Date(object.toDatetime),
            new Date(object.fromDatetime)
          ) <= 1),
      ...object,
    }))

    .filter((event) => isBefore(Date.now(), new Date(event.fromDatetime)))
    .sort(
      (a, b) =>
        new Date(a.fromDatetime).getTime() - new Date(b.fromDatetime).getTime()
    );
});
