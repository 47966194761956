import { set, toggle } from 'cerebral/operators';
import { state, props } from 'client/app.cerebral';

import * as actions from './actions';
import { goTo as gotToFactory } from './factories';

export const resetModal = [
  set(state.app.modal.type, null),
  set(state.app.modal.sequences, null),
  set(state.app.modal.payload, {}),
];

export const openWithToken = [actions.getToken, actions.openWithToken];

export const toggleMenu = [toggle(state.app.menuExpanded)];

export const termsAccepted = [actions.termsAccepted, actions.setTermsAccepted];

export const setCurrentModal = [set(state.app.currentModal, props`modal`)];

export const sendMessage = [actions.sendMessage];

export const addUrl = [
  actions.getDownloadURL,
  {
    success: [set(state.urls[props`path`], props`url`)],
    error: [],
  },
];

export const goTo = [gotToFactory(props`path`)];

export const imageClicked = [set(state.app.overlayImagePath, props`path`)];

export const setLanguage = [set(state.language, props`language`)];
