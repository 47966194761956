import React, { ButtonHTMLAttributes } from 'react';

import { Button } from './elements';

type Props = {
  className?: string;
  children: React.ReactNode;
  disabled?: boolean;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
};

export default function SubmitButton({
  className,
  children,
  disabled,
  type,
}: Props): React.ReactElement {
  return (
    <Button className={className} disabled={disabled} type={type}>
      {children}
    </Button>
  );
}
