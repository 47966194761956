import React from 'react';
import { connect, ConnectedProps } from '@cerebral/react';
import { state, props, sequences } from 'client/app.cerebral';

import ImageCard from 'components/ImageCard';
import { useText } from 'common/hooks';

type Props = {
  className?: string;
  imageKey: string;
};

const deps = {
  image:
    state.competition.competitions[state.competition.currentCompetition].images[
      props`imageKey`
    ],
  profiles: state.profiles,
  uid: state.app.uid,
  removeImageClicked: sequences`competition.removeImageClicked`,
  badgeSelected: sequences`competition.badgeSelected`,
};

function Image({
  className,
  image,
  imageKey,
  profiles,
  uid,
  removeImageClicked,
  badgeSelected,
}: Props & typeof deps & ConnectedProps): React.ReactElement {
  const t = useText();

  return (
    <ImageCard
      className={className}
      path={image.imageUrl}
      showRemove={Boolean(uid && (profiles[uid].isAdmin || image.uid === uid))}
      onClickRemove={() =>
        removeImageClicked({
          imageKey: imageKey,
          competitionKey: image.competitionKey,
        })
      }
      title={image.title}
      description={
        profiles[image.uid]
          ? `${t('common.photo')}: ${profiles[image.uid].name}`
          : ''
      }
      showEditBadge={Boolean(uid && profiles[uid].isAdmin)}
      onBadgeSelected={(badge) =>
        badgeSelected({
          badge: badge,
          competitionKey: image.competitionKey,
          imageKey: imageKey,
          uid: image.uid,
        })
      }
      badge={image.badge}
    />
  );
}

export default connect(deps, Image);
