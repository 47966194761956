import React, { useState } from 'react';
import { connect, ConnectedProps } from '@cerebral/react';
import { state } from 'client/app.cerebral';
import page from 'page';
import { getAuth } from 'firebase/auth';
import configs from '../../../configs/client.json';
import { Wrapper, Header, Title, Button, Error } from './elements';

import Input from 'common/Input';
import ImageInput from 'common/ImageInput';
import { useText } from 'common/hooks';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { createSchema } from './schema';

const config =
  configs[(process.env.SKK_ENV as keyof typeof configs) || 'skk-dev'];

type Props = {
  className?: string;
};

const deps = {
  competitionKey: state.competition.currentCompetition,
  competition: state.competition.list[state.competition.currentCompetition],
};

// eslint-disable-next-line max-lines-per-function
export default function EditCompetitionHOC({ newComp }: { newComp?: boolean }) {
  // eslint-disable-next-line max-lines-per-function
  function EditCompetition({
    competitionKey,
    competition,
    className,
  }: Props & typeof deps & ConnectedProps): React.ReactElement | null {
    if (!competition && !newComp) return null;

    const schema = createSchema(newComp);

    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const t = useText();
    const {
      register,
      handleSubmit,
      formState: { errors, isValid },
    } = useForm({
      mode: 'onChange',
      resolver: yupResolver(schema),
      defaultValues: {
        title: competition?.title,
        description: competition?.description,
        maxImages: competition?.maxImages,
        fromDatetime: new Date(competition?.fromDatetime ?? undefined),
        toDatetime: new Date(competition?.toDatetime ?? undefined),
        imageUrl: undefined,
      },
    });

    return (
      <Wrapper className={className}>
        <Header>
          {t(
            newComp
              ? 'competition.newCompetition.title'
              : 'competition.editCompetition.title'
          )}
        </Header>
        {!newComp && <Title>{competition.title}</Title>}
        {error ? <Error>{t('errors.error', { error })}</Error> : null}
        <form
          onSubmit={handleSubmit((data, e) => {
            const formData = new FormData(e?.target);

            if (!newComp) formData.set('competitionKey', competitionKey);
            setIsLoading(true);

            return getAuth()
              ?.currentUser?.getIdToken()
              .then((token) =>
                fetch(
                  `${config.functionsUrl}/${
                    newComp ? 'create' : 'edit'
                  }Competition`,
                  {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                    body: formData,
                  }
                )
              )
              .then(() => {
                page(`/competitions`);
              })
              .catch((err) => {
                setError(err);
                setIsLoading(false);
              });
          })}
        >
          <Input
            type={'text'}
            name={'title'}
            title={t('competition.newCompetition.form.title')}
            placeholder={t('competition.newCompetition.form.title') as string}
            required
            register={register}
            error={errors.title?.message ? t(errors.title.message) : null}
          />
          <Input
            type={'textArea'}
            name={'description'}
            title={t('competition.newCompetition.form.description')}
            placeholder={
              t('competition.newCompetition.form.description') as string
            }
            required
            register={register}
            error={
              errors.description?.message ? t(errors.description.message) : null
            }
          />
          <Input
            type={'datetime-local'}
            name={'fromDatetime'}
            title={t('competition.newCompetition.form.from')}
            required
            register={register}
            error={
              errors.fromDatetime?.message
                ? t(errors.fromDatetime.message)
                : null
            }
          />
          <Input
            type={'datetime-local'}
            name={'toDatetime'}
            title={t('competition.newCompetition.form.to')}
            required
            register={register}
            error={
              errors.toDatetime?.message ? t(errors.toDatetime.message) : null
            }
          />
          {newComp ? (
            <ImageInput
              name={'imageUrl'}
              title={t('competition.newCompetition.form.coverImage')}
              required
              register={register}
              error={
                errors.imageUrl?.message ? t(errors.imageUrl.message) : null
              }
            />
          ) : null}
          <Input
            type={'number'}
            name={'maxImages'}
            title={t('competition.newCompetition.form.maxParticipants')}
            min={1}
            step={1}
            required
            register={register}
            error={
              errors.maxImages?.message ? t(errors.maxImages.message) : null
            }
          />
          {newComp ? (
            <Input
              type={'checkbox'}
              name={'isVisible'}
              title={t('competition.newCompetition.form.isVisible')}
              register={register}
            />
          ) : null}
          <Button type={'submit'} disabled={!isValid || isLoading}>
            {t(
              newComp
                ? isLoading
                  ? 'competition.newCompetition.form.creating'
                  : 'competition.newCompetition.form.submit'
                : isLoading
                ? 'competition.editCompetition.form.saving'
                : 'competition.editCompetition.form.submit'
            )}
          </Button>
        </form>
      </Wrapper>
    );
  }

  return connect<Props, typeof deps>(deps, EditCompetition);
}
