import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Wrapper = styled.div`
  margin-right: 0.5rem;
  font-size: 1.2rem;
`;

export const Trophy = styled(FontAwesomeIcon)`
  color: ${(props) => props.color};
`;
