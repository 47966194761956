import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Wrapper = styled.div`
  max-width: 400px;
  max-height: 400px;
  height: 100%;
  background-color: white;
  padding: 10px 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 10px;
`;

type AddIconProps = {
  disabled?: boolean;
};

export const AddIcon = styled(FontAwesomeIcon)<AddIconProps>`
  font-size: 150px;
  line-height: 150px;
  color: ${({ disabled }) => (disabled ? 'gray' : 'green')};
  cursor: ${({ disabled }) => (disabled ? 'inherit' : 'pointer')};
  &:hover {
    opacity: ${({ disabled }) => (disabled ? 1 : 0.8)};
  }
`;
