import styled from 'styled-components';
import FiltersComponent from './Filters';

import TableComponent from './Table';

export const Wrapper = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  padding: 14px;
`;

export const Header = styled.h1``;

export const Table = styled(TableComponent)``;

export const Filters = styled(FiltersComponent)``;
