import { Badge } from '../types';

const semesters = {
  autumn: 11,
  spring: 7,
};

export function memberDatetime({
  semester,
  year,
}: {
  semester: keyof typeof semesters;
  year: number;
}): number {
  return new Date(year, semesters[semester], 25).getTime();
}

export function toDatetimeLocal(epoch: number): string {
  function ten(i: number): string {
    return (i < 10 ? '0' : '') + i;
  }

  const date = new Date(epoch);

  const YYYY = date.getFullYear();
  const MM = ten(date.getMonth() + 1);
  const DD = ten(date.getDate());
  const HH = ten(date.getHours());
  const II = ten(date.getMinutes());
  const SS = ten(date.getSeconds());

  return `${YYYY}-${MM}-${DD}T${HH}:${II}:${SS}`;
}

const badges = {
  gold: {
    color: 'gold',
    icon: 'faTrophy',
  },
  silver: {
    color: 'silver',
    icon: 'faTrophy',
  },
  bronze: {
    color: '#CD7F32',
    icon: 'faTrophy',
  },
  star: {
    color: 'gray',
    icon: 'faStar',
  },
  none: {
    color: 'inherit',
    icon: null,
  },
} as const;

export function getBadge(badge: Badge = 'none'): {
  icon: typeof badges[Badge]['icon'];
  color: string;
} {
  return badges[badge];
}

type AllBadges = {
  key: Badge;
  color: string;
  icon: 'faTrophy' | 'faStar';
};

export function getAllBadges() {
  return Object.entries(badges)
    .filter(([key]) => key !== 'none')
    .map(([key, badge]) => ({ key, ...badge })) as AllBadges[];
}
