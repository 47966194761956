import React from 'react';
import { connect, ConnectedProps } from '@cerebral/react';
import { state } from 'client/app.cerebral';
import { Wrapper, Header, BodyText, Steps, StepsTitle } from './elements';
import { useText } from 'common/hooks';
import Step from './Step';

type Props = {
  className?: string;
};

const deps = {
  user: state.app.user,
  profile: state.app.profile,
  isActiveMember: state.app.isActiveMember.isActive,
};

function Join({
  className,
  user,
  profile,
  isActiveMember,
}: Props & typeof deps & ConnectedProps): React.ReactElement {
  const t = useText();

  return (
    <Wrapper className={className}>
      <Header>{t('joinUs.title')}</Header>
      <BodyText>{t('joinUs.firstParagraph')}</BodyText>
      <StepsTitle>{t('joinUs.steps')}</StepsTitle>
      <Steps>
        <Step title={t('joinUs.firstStepTitle')} completed={Boolean(user)}>
          <p>{t('joinUs.firstStep')}</p>
        </Step>
        <Step
          title={t('joinUs.secondStepTitle')}
          completed={Boolean(profile && profile.mails && profile.mails.welcome)}
        >
          <p>{t('joinUs.secondStep')}</p>
        </Step>
        <Step
          title={t('joinUs.thirdStepTitle')}
          completed={Boolean(profile && isActiveMember)}
        >
          <p>{t('joinUs.thirdStep')}</p>
        </Step>
      </Steps>
    </Wrapper>
  );
}

export default connect<Props, typeof deps>(deps, Join);
