import computeEventsList from 'computed/computeEventsList';
import * as sequences from './sequences';
import { OwnState } from './types';

const state: OwnState = {
  list: {},
  loadingEvents: true,
  eventsList: computeEventsList,
};

export default {
  state,
  sequences: {
    updateEventsClicked: sequences.updateEvents,
  },
};
