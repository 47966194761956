export default {
  title: 'Studentenes Kameraklubb',
  home: 'Hjem',
  joinUs: 'Bli med',
  contact: 'Kontakt oss',
  gallery: 'Galleri',
  about: 'Om oss',
  login: 'Logg inn',
  logout: 'Logg ut',
  competitions: 'Bilder',
  profile: 'Min Profil',
  events: 'Arrangementer',
  members: 'Medlemmer',
  booking: 'Kjellerbooking',
};
