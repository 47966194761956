import React from 'react';
import { connect, ConnectedProps } from '@cerebral/react';
import { state } from 'client/app.cerebral';

import { Wrapper, SectionHeader, Loading, MenuBar, InfoBar } from './elements';

import Gallery from './Gallery';
import Presentation from './Presentation';

import { useText } from 'common/hooks';

type Props = {
  className?: string;
};

const deps = {
  user: state.app.user,
  competition: state.competition.list[state.competition.currentCompetition],
  isPresentation: state.competition.isPresentation,
};

function Competition({
  className,
  competition,
  user,
  isPresentation,
}: Props & typeof deps & ConnectedProps): React.ReactElement {
  const t = useText();

  if (!user) {
    return (
      <Wrapper className={className}>
        <Loading>{t('common.notLoggedIn')}</Loading>
      </Wrapper>
    );
  }

  if (!competition) {
    return (
      <Wrapper className={className}>
        <Loading>{t('common.loadingCompetition')}</Loading>
      </Wrapper>
    );
  }

  return (
    <Wrapper className={className}>
      {isPresentation ? (
        <Presentation />
      ) : (
        <>
          <SectionHeader>{competition.title}</SectionHeader>
          <InfoBar />
          <MenuBar />
          <Gallery />
        </>
      )}
    </Wrapper>
  );
}

export default connect<Props, typeof deps>(deps, Competition);
