import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ImageComponent from 'common/Image';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 370px;
  max-height: 400px;
  height: fit-content;
  background-color: black;
  color: white;
  outline: 5px solid ${(props) => props.color};
  border-radius: 5px;
  width: 100%;
  margin: 10px;
`;
export const Image = styled(ImageComponent)`
  max-width: 360px;
  height: 240px;
  width: 100%;
  margin-top: 5px;
  align-self: center;
`;

export const Description = styled.p`
  height: fit-content;
  font-size: 0.9rem;
  margin: 0.5rem;
`;

export const HeaderWrapper = styled.div`
  position: relative;
  top: 1.2em;
  padding: 0 0.5em;
  height: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: flex-end;
`;

export const Header = styled.span`
  font-size: 1.4rem;
  margin: 0.5rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Remove = styled(FontAwesomeIcon)`
  font-size: 1.5rem;
  cursor: pointer;
  & > path {
    fill: #c61e35;
  }
  &:hover {
    & > path {
      fill: red;
    }
  }
`;

export const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
`;
