import styled from 'styled-components';

import MenuComponent from './NewMenu';
import FooterComponent from './Footer';
import ImageOverlayComponent from './ImageOverlay';
import LoginButtonComponent from './LoginButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type SidebarProps = {
  expanded?: boolean;
};

export const ContentWrapper = styled.main``;
export const MainContent = styled.div`
  width: 80%;
  height: 100%;
  margin-left: 20%;
  margin-top: 5rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 1024px) {
    width: 75%;
    margin-left: 25%;
  }
  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
    margin-left: 0;
  }
`;
export const Sidebar = styled.div<SidebarProps>`
  display: flex;
  flex-direction: column;
  padding-top: 10em;
  width: 20%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  @media (max-width: 1024px) {
    width: 25%;
    padding-top: 5em;
  }
  @media (max-width: 768px) {
    padding-top: 5em;
    background-color: white;
    flex-direction: column;
    position: absolute;
    transition: width 200ms ease-in-out;
    width: ${({ expanded }) => (expanded ? '100%' : '0')};
    overflow: hidden;
    z-index: 99;
  }
`;
export const LoginButton = styled(LoginButtonComponent)`
  font-size: 1.2rem;
  @media (max-width: 768px) {
    font-size: 1rem;
    margin-right: 0.5em;
  }
`;
export const Menu = styled(MenuComponent)``;
export const Footer = styled(FooterComponent)`
  width: 100%;
`;

export const Wrapper = styled.div``;

export const ImageOverlay = styled(ImageOverlayComponent)``;

export const Hamburger = styled(FontAwesomeIcon)`
  display: none;
  font-size: 25px;
  line-height: 25px;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;

export const TopNav = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: fit-content;
  align-items: center;
  padding: 1rem;
  position: fixed;
  top: 0;
  z-index: 100;
  @media (max-width: 768px) {
    background-color: white;
  }
`;

export const LogoLink = styled.a`
  display: none;
  margin-right: auto;
  width: 30px;
  height: 30px;
  @media (max-width: 768px) {
    display: block;
  }
`;
export const Image = styled.img`
  max-width: 30px;
  height: 30px;
`;
