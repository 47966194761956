import { state } from 'client/app.cerebral';
import { set, when } from 'cerebral/operators';
import * as actions from './actions';

export const eventsRouted = [
  actions.getEvents,
  {
    success: [actions.setEvents],
    error: [],
  },
  set(state.events.loadingEvents, false),
  actions.getLastUpdatedEvents,
  {
    success: [
      actions.checkIfUpdate,
      {
        true: [
          when(state.app.user),
          {
            true: [actions.updateEvents],
            false: [],
          },
        ],
        false: [],
      },
    ],
    error: [],
  },
];

export const updateEvents = [actions.updateEvents, ...eventsRouted];
