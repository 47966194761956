export default {
  title: 'Medlemmer (%{count})',
  table: {
    name: 'Navn',
    email: 'E-post',
    emails: 'E-poster',
    terms: 'Vilkår',
    isAdmin: 'Er admin',
    memberUntil: {
      title: 'Medlem til',
      spring: 'Vår',
      autumn: 'Høst',
      semester: {
        spring: 'Vår %{year}',
        autumn: 'Høst %{year}',
      },
    },
    newPayment: 'Ny betaling',
  },
  filters: {
    hasBeenMembers: 'Medlemmer',
    currentMembers: 'Betalende medlemmer',
  },
};
