import React from 'react';
import { useText } from 'common/hooks';

import {
  Events,
  JoinButton,
  Section,
  SectionHeader,
  SectionBody,
  SectionText,
  SendMessage,
  Wrapper,
  Carousel,
  Galleries,
} from './elements';

export default function Landing() {
  const t = useText();

  return (
    <Wrapper>
      <Section>
        <SectionBody>
          <SectionHeader>{t('frontPage.firstSection.title')}</SectionHeader>
          <SectionText>{t('frontPage.firstSection.text')}</SectionText>
        </SectionBody>
        <JoinButton href={'/join'}>{t('frontPage.join')}</JoinButton>
      </Section>
      <Events />
      <Carousel />
      <Galleries />
      <SendMessage />
    </Wrapper>
  );
}
