import styled from 'styled-components';

import EventComponent from './Event';

export const Wrapper = styled.div`
  margin: 20px;
  @media (max-width: 768px) {
    margin: 0;
  }
`;

export const Loading = styled.h2`
  text-align: center;
`;

export const Event = styled(EventComponent)``;
