import React from 'react';
import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/free-regular-svg-icons';
import { Wrapper, Title, Description, Icon } from './elements';

type Props = {
  className?: string;
  title: React.ReactNode;
  completed?: boolean;
  children: React.ReactNode;
};

export default function Step({
  className,
  title,
  completed,
  children,
}: Props): React.ReactElement {
  return (
    <Wrapper className={className}>
      <Title completed={completed}>
        <Icon
          icon={completed ? faCheckCircle : faTimesCircle}
          color={completed ? 'green' : 'red'}
        />
        {title}
      </Title>
      <Description>{children}</Description>
    </Wrapper>
  );
}
