import { state, props } from 'client/app.cerebral';
import { set, when, toggle } from 'cerebral/factories';

import * as actions from './actions';

export const membersRouted = [
  actions.getAllProfiles,
  {
    success: [set(state.profiles, props`profiles`)],
    error: set(state.members.error, 'getMembersFailed'),
  },
  actions.onProfileChanged,
];

export const editMember = [
  when(props`uid`),
  {
    true: [
      set(state.members.edit.uid, props`uid`),
      when(state.profiles[props`uid`].memberUntil),
      {
        true: [
          set(
            state.members.edit.semester,
            state.profiles[props`uid`].memberUntil.semester
          ),
          set(
            state.members.edit.year,
            state.profiles[props`uid`].memberUntil.year
          ),
        ],
        false: [
          set(state.members.edit.semester, 'autumn'),
          set(state.members.edit.year, 2018),
        ],
      },
    ],
    false: [
      set(state.members.edit.uid, null),
      set(state.members.edit.semester, null),
      set(state.members.edit.year, null),
    ],
  },
];

export const setSemester = [set(state.members.edit.semester, props`semester`)];
export const setYear = [set(state.members.edit.year, props`year`)];

export const updateMemberUntil = [
  actions.updateMemberUntil,
  {
    success: [
      set(state.members.edit.uid, null),
      set(state.members.edit.semester, null),
      set(state.members.edit.year, null),
    ],
    error: [],
  },
];

export const memberChanged = [set(state.profiles[props`key`], props`value`)];

export const setWillSendMail = [
  when(props`mails`),
  {
    true: set(state.members.willSendMail[props`uid`], props`mails`),
    false: [
      set(state.members.willSendMail[props`uid`].welcome, false),
      set(state.members.willSendMail[props`uid`].info, false),
    ],
  },
];

export const sendWelcomeEmail = [
  setWillSendMail,
  when(state.members.isSendingMail[props`uid`]),
  {
    true: [],
    false: [
      set(state.members.isSendingMail[props`uid`], {
        welcome: false,
        info: false,
      }),
      set(state.members.isSendingMail[props`uid`].welcome, false),
      set(state.members.isSendingMail[props`uid`].info, false),
    ],
  },
  set(state.members.isSendingMail[props`uid`].welcome, true),
  actions.sendWelcomeEmail,
  {
    success: [],
    error: [],
  },
  set(state.members.isSendingMail[props`uid`].welcome, false),
];

export const sendInfoEmail = [
  setWillSendMail,
  when(state.members.isSendingMail[props`uid`]),
  {
    true: [],
    false: [
      set(state.members.isSendingMail[props`uid`], {
        welcome: false,
        info: false,
      }),
      set(state.members.isSendingMail[props`uid`].welcome, false),
      set(state.members.isSendingMail[props`uid`].info, false),
    ],
  },
  set(state.members.isSendingMail[props`uid`].info, true),
  actions.sendInfoEmail,
  {
    success: [],
    error: [],
  },
  set(state.members.isSendingMail[props`uid`].info, false),
];

export const sortBy = [
  when(state.members.sortBy, props`by`, (a, b) => a === b),
  {
    false: [set(state.members.sortBy, props`by`)],
    true: [actions.invertSort],
  },
];

export const setFilter = [
  toggle(
    state.members.filters[props`filter` as keyof typeof state.members.filters]
  ),
];

export const sendWelcomeEmailToMyself = [
  set(props`uid`, state.app.uid),
  when(props`uid`),
  {
    true: [
      actions.sendWelcomeEmail,
      {
        // eslint-disable-next-line no-alert
        error: [() => alert('Failed. Did you log in?')],
        // eslint-disable-next-line no-alert
        success: [() => alert('Sent')],
      },
    ],
    false: [],
  },
];
