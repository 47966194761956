import React from 'react';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';

import {
  Wrapper,
  Header,
  Image,
  Description,
  Remove,
  HeaderWrapper,
  LeftWrapper,
} from './elements';

import BadgeComponent from './Badge';
import { Badge } from 'types';
import { getBadge } from '../../utils/common';

type Props = {
  className?: string;
  path: string;
  onClickImage?: () => void;
  showRemove?: boolean;
  onClickRemove: () => void;
  title: string;
  description: string;
  showEditBadge?: boolean;
  onBadgeSelected?: (badge: Badge) => void;
  badge?: Badge;
};

function ImageCard({
  className,
  path,
  onClickImage,
  showRemove,
  onClickRemove,
  title,
  description,
  showEditBadge,
  onBadgeSelected,
  badge,
}: Props): React.ReactElement | null {
  if (!path) {
    return <h3>{'Loading...'}</h3>;
  }

  const { color } = getBadge(badge);

  return (
    <Wrapper className={className} color={color}>
      <HeaderWrapper>
        <LeftWrapper>
          <BadgeComponent
            showEditBadge={showEditBadge}
            value={badge}
            onBadgeSelected={onBadgeSelected}
          />
        </LeftWrapper>
        {showRemove ? (
          <Remove
            tabIndex={0}
            role={'button'}
            icon={faTimesCircle}
            onClick={onClickRemove}
          />
        ) : null}
      </HeaderWrapper>
      <Image path={path} onClick={onClickImage} />
      <Header title={title}>{title}</Header>
      <Description>{description}</Description>
    </Wrapper>
  );
}

export default ImageCard;
