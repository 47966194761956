import React from 'react';
import { connect, ConnectedProps } from '@cerebral/react';
import { sequences } from 'cerebral';

import { Title, Content, Heading, Scroller, Accept } from './elements';

import Modal from 'common/Modal';

import { useText } from 'common/hooks';

const deps = {
  termsAccepted: sequences`app.termsAccepted`,
};

function Terms({
  termsAccepted,
}: typeof deps & ConnectedProps): React.ReactElement {
  const t = useText();

  return (
    <Modal id={'terms'}>
      <Title>{t('terms.title')}</Title>
      <Scroller>
        <Heading>{t('terms.welcomeHeading')}</Heading>
        <Content>{t('terms.welcome')}</Content>
        <Heading>{t('terms.introductionHeading')}</Heading>
        <Content>{t('terms.introduction')}</Content>
        <Heading>{t('terms.whatHeading')}</Heading>
        <Content>{t('terms.what')}</Content>
        <Heading>{t('terms.storageHeading')}</Heading>
        <Content>{t('terms.storage')}</Content>
        <Heading>{t('terms.emailHeading')}</Heading>
        <Content>{t('terms.email')}</Content>
        <Heading>{t('terms.childrenHeading')}</Heading>
        <Content>{t('terms.children')}</Content>
        <Heading>{t('terms.personalHeading')}</Heading>
        <Content>{t('terms.personal')}</Content>
        <Heading>{t('terms.linksHeading')}</Heading>
        <Content>{t('terms.links')}</Content>
        <Heading>{t('terms.consentHeading')}</Heading>
        <Content>{t('terms.consent')}</Content>
        <Heading>{t('terms.erasingHeading')}</Heading>
        <Content>{t('terms.erasing')}</Content>
        <Heading>{t('terms.transferHeading')}</Heading>
        <Content>{t('terms.transfer')}</Content>
        <Heading>{t('terms.getHeading')}</Heading>
        <Content>{t('terms.get')}</Content>
        <Heading>{t('terms.dueHeading')}</Heading>
        <Content>{t('terms.due')}</Content>
        <Heading>{t('terms.useHeading')}</Heading>
        <Content>{t('terms.use')}</Content>
        <Heading>{t('terms.contactHeading')}</Heading>
        <Content>{t('terms.contact')}</Content>
      </Scroller>
      <Accept onClick={() => termsAccepted()}>{t('terms.accept')}</Accept>
    </Modal>
  );
}

export default connect(deps, Terms);
