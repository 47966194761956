import App from 'cerebral';
import Devtools from 'cerebral/devtools';
import page from 'page';
import routes from './routes';
import rootModule from '../modules';

const isProd = process.env.NODE_ENV === 'production';

export default App(
  ({ app }) => {
    app.on('initialized', () => {
      page.start();
      const loader = document.querySelector('#loaderWrapper');

      if (loader) {
        loader.className = 'hidden';
      }
    });

    routes.forEach((route) => {
      page(route.path, ({ params }) => {
        app.getSequence(route.signal)({
          ...params,
          path: route.path,
          name: route.name,
        });
      });
    });

    return rootModule;
  },
  {
    devtools:
      !isProd &&
      Devtools({
        host: 'localhost:5656',
      }),
  }
);
