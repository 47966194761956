import { type Auth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { createUser } from './helpers';
import { FirebaseProviderAuthenticationError } from './errors';

export default function signInWithGoogle(auth: Auth) {
  const provider = new GoogleAuthProvider();

  return signInWithPopup(auth, provider)
    .then((result) => {
      return { user: createUser(result.user) };
    })
    .catch((error) => {
      throw new FirebaseProviderAuthenticationError(error);
    });
}
