import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Wrapper = styled.div`
  padding-top: 20px;
`;

export const TopWrapper = styled.div`
  margin: auto;
  max-width: 800px;
`;

export const Name = styled.h1`
  text-align: center;
  margin-top: 10px;
`;

export const ImageTitle = styled.h2`
  text-align: center;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Image = styled.img`
  border-radius: 50%;
  max-width: 100px;
  max-height: 100px;
`;

export const AwardsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px auto 0;
  max-width: 100px;
`;

type AwardIconType = {
  color?: string;
};

export const AwardIcon = styled(FontAwesomeIcon)<AwardIconType>`
  color: ${({ color }) => color};
  font-size: 40px;
`;

export const Award = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 10px;
`;

export const AwardCount = styled.div`
  font-size: 30px;
  text-align: center;
`;

type StatusProps = {
  active?: boolean;
};

export const Status = styled.p<StatusProps>`
  color: ${({ active }) => (active ? 'inherit' : 'red')};
  text-align: center;
`;
export const Code = styled.p`
  text-align: center;
  font-weight: bold;
  margin: 1.5em auto;
  font-size: 1.2rem;
`;

export const Button = styled.a`
  display: block;
  width: fit-content;
  color: black;
  text-decoration: none;
  font-size: 1.2rem;
  margin: 1em auto;
  padding: 0.5em;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  border-radius: 100em;
  &:hover {
    background-color: #ee264220;
  }
  &:active {
    background-color: #ee264240;
  }
`;
