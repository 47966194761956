import { Compute } from 'cerebral';
import { state } from 'client/app.cerebral';

export default Compute((get) => {
  const competition = get(
    state.competition.list[state.competition.currentCompetition]
  );

  if (!competition) return false;

  const date = new Date().getTime();

  return competition.fromDatetime < date && competition.toDatetime > date;
});
