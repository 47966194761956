export default `MARKDOWN:
  Studentenes Kameraklubb provides an extensive range of photographic equipment. 
  Recognising that students often face financial constraints, we offer unrestricted 
  access to all available resources, enabling you to allocate your funds more 
  effectively. To develop analog film, you only need to acquire chemicals. Our 
  members benefit from a 10% discount on all purchases from Fotoimport AS. As of 
  Spring 2023, we also provide limited complimentary use of both colour and black 
  and white chemicals during Darkroom Sundays for our members. 

  Additionally, we maintain an archive comprising photo books and magazines for 
  those interested in exploring further.

  ### Membership fee:
  - 350 NOK per year
  - 250 NOK per semester


  ### Member benefits:
  - Access to our darkroom and basement at Moholt student village.
  - Access to trips and activities.
  - 10% discount at Fotoimport AS.
  - 15% discount on prints from CEWE Japan Photo.
  - 15% discount on everything analog from CEWE Japan Photo.
  - 15% discount on development from CEWE Japan Photo.
`;
