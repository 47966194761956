import * as sequences from './sequences';
import computeMembersList from 'computed/computeMembersList';

export default {
  state: {
    list: computeMembersList,
    sortBy: 'name',
    sortDirection: 1,
    filters: {
      hasBeenMembers: false,
      currentMembers: false,
    },
    edit: {
      uid: null,
    },
    isSendingMail: {},
    willSendMail: {},
    error: null,
  },
  sequences: {
    editMember: sequences.editMember,
    setSemester: sequences.setSemester,
    setYear: sequences.setYear,
    updateMemberUntil: sequences.updateMemberUntil,
    memberChanged: sequences.memberChanged,
    setWillSendMail: sequences.setWillSendMail,
    sendWelcomeEmail: sequences.sendWelcomeEmail,
    sendInfoEmail: sequences.sendInfoEmail,
    sortBy: sequences.sortBy,
    setFilter: sequences.setFilter,
    sendWelcomeEmailToMyself: sequences.sendWelcomeEmailToMyself,
  },
};
