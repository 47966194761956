import React from 'react';
import badges from 'configs/badges.json';
import { faTrophy, faStar } from '@fortawesome/free-solid-svg-icons';

const icons = { faTrophy, faStar };

import { Wrapper, Trophy } from './elements';
import { Badge } from 'types';
import { getBadge } from '../../../utils/common';

type Props = {
  className?: string;
  value?: Badge;
  onBadgeSelected?: (badge: Badge) => void;
  showEditBadge?: boolean;
};

export default function BadgeComponent({
  className,
  value = 'none',
  onBadgeSelected,
  showEditBadge,
}: Props): React.ReactElement | null {
  const { icon, color } = getBadge(value);

  if (showEditBadge)
    return (
      <Wrapper className={className}>
        <select
          value={value}
          onChange={(e) => {
            if (onBadgeSelected) {
              onBadgeSelected(e.target.value as Badge);
            }
          }}
        >
          {Object.keys(badges).map((b) => (
            <option key={b} value={b}>
              {b.toUpperCase().substring(0, 1) + b.substring(1)}
            </option>
          ))}
          <option key={'none'} value={'none'}>
            {'None'}
          </option>
        </select>
      </Wrapper>
    );

  if (!value) {
    return null;
  }

  if (!icon) {
    return null;
  }

  return (
    <Wrapper className={className}>
      <Trophy icon={icons[icon]} color={color} />
    </Wrapper>
  );
}
