import * as React from 'react';
import { Container, connect, ConnectedProps } from '@cerebral/react';
import { state } from 'client/app.cerebral';
import {
  ContentWrapper,
  Footer,
  Hamburger,
  Image,
  ImageOverlay,
  LoginButton,
  LogoLink,
  MainContent,
  Menu,
  Sidebar,
  TopNav,
  Wrapper,
} from './elements';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import ErrorBoundary from 'components/ErrorBoundary';
import Modals from './Modals';
import LanguageContext from '../LanguageContext';

import TermsModal from './Terms';
import LandingPage from './Landing';
import JoinUsPage from './Join';
import EventsPage from './Events';
import CompetitionsPage from './Competitions';
import CompetitionPage from './Competition';
import NewImagePage from './NewImage';
import ProfilePage from './Profile';
import NewCompetitionPage from './EditCompetition/New';
import EditCompetitionPage from './EditCompetition/Edit';
import MembersPage from './Members';
import AboutPage from './About';
import KronikenPage from './Kroniken';
import BookingPage from './Booking';
import NotFoundPage from './NotFound';
import { BaseControllerClass, sequences } from 'cerebral';

const pages: { [key: string]: () => React.ReactElement } = {
  frontPage: LandingPage,
  joinUs: JoinUsPage,
  competitions: CompetitionsPage,
  competition: CompetitionPage,
  editCompetition: EditCompetitionPage,
  newCompetition: NewCompetitionPage,
  newImage: NewImagePage,
  profile: ProfilePage,
  events: EventsPage,
  members: MembersPage,
  about: AboutPage,
  kroniken: KronikenPage,
  booking: BookingPage,
  notFound: NotFoundPage,
};

const deps = {
  page: state.page,
  profile: state.app.profile,
  language: state.language,
  isPresentation: state.competition.isPresentation,
  toggleMenu: sequences`app.toggleMenu`,
  menuExpanded: state.app.menuExpanded,
};

function AppComp({
  page,
  profile,
  language,
  isPresentation,
  toggleMenu,
  menuExpanded,
}: typeof deps & ConnectedProps): React.ReactElement {
  function renderPage() {
    if (page in pages) {
      const Page = pages[page];

      return <Page />;
    }

    return <LandingPage />;
  }
  const showTerms = profile && !profile.termsAccepted;

  return (
    <LanguageContext.Provider value={language}>
      <ErrorBoundary>
        {isPresentation ? null : (
          <TopNav>
            <LogoLink href={'../'}>
              <Image src={'/images/SK_logo_400.png'} alt={'SK logo'} />
            </LogoLink>
            <LoginButton />
            <Hamburger onClick={toggleMenu} icon={faBars} />
          </TopNav>
        )}
        <Sidebar expanded={menuExpanded}>
          <Menu toggleMenu={toggleMenu} />
        </Sidebar>
        <MainContent>
          <Wrapper>
            {showTerms ? <TermsModal /> : null}
            <Modals />
            <ImageOverlay />
            <ErrorBoundary>
              <ContentWrapper>{renderPage()}</ContentWrapper>
            </ErrorBoundary>
          </Wrapper>
          <Footer />
        </MainContent>
      </ErrorBoundary>
    </LanguageContext.Provider>
  );
}

const AppComponent = connect(deps, AppComp);

// eslint-disable-next-line
export default function App({
  controller,
}: {
  controller: BaseControllerClass;
}) {
  return (
    <Container app={controller}>
      <AppComponent />
    </Container>
  );
}
