import { Compute } from 'cerebral';
import { state } from 'client/app.cerebral';

export default Compute((get) => {
  const profile = get(state.app.profile);

  if (!profile) return false;

  return Boolean(profile.isAdmin);
});
