import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 50%;
  @media (max-width: 768px) {
    width: 90%;
  }
`;
export const Header = styled.h1`
  font-size: 4rem;
  margin: 0 auto;
  padding: 0.4em;
  margin-bottom: 1rem;
  text-align: center;
  font-weight: 800;
  width: fit-content;
  background-image: linear-gradient(to right, #ee2642 50px, transparent 50px),
    linear-gradient(to right, #ee2642 50px, transparent 50px),
    linear-gradient(to bottom, #ee2642 50px, transparent 50px),
    linear-gradient(to bottom, #ee2642 50px, transparent 50px);
  background-size: 100% 5px, 100% 5px, 5px 100%, 5px 100%;
  background-position: -25px 0%, -25px 100%, 0% -25px, 100% -25px;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
`;
export const Paragraph = styled.p`
  font-size: 1rem;
  margin-bottom: 1rem;
  line-height: 1.5rem;
`;
export const Link = styled.a`
  color: #ee2642;
  text-decoration: none;
  font-weight: bold;
  margin-bottom: 1rem;
  transition: opacity 0.2s ease-in-out;
  &:hover {
    opacity: 0.8;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;
