import styled from 'styled-components';

type ImageProps = {
  url: string;
};

export const Image = styled.div<ImageProps>`
  background-image: url(${(props) => props.url});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid gray;
  width: 360px;
  height: 280px;
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const Feedback = styled.p`
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
`;
