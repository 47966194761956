export default `MARKDOWN:
  Studentenes Kameraklubb tilbyr et variert utvalg av utstyr innen fotokunsten. Vi erkjenner viktigheten
  av økonomistyring for studenter, og derfor tilbyr vi våre medlemmer en mengde fordeler for å sikre at du kan
  bruke pengene dine der det betyr mest for deg.
  
  For analog filmutvikling, er kjemikalier det eneste du trenger å skaffe deg. Vi har gleden av å tilby alle våre
  medlemmer 10% rabatt på alle kjøp hos Fotoimport AS. Fra våren 2023 vil vi også kunne tily en begrenset
  mengde gratis kjemikalier for både farge- og svart-hvitt film på våre Mørkeromssøndager.
  
  For de med en dypere interesse for fotografi har vi også et eget bibliotek og arkiv. Her finner du en rekke fotobøker,
  både tekniske og kunstneriske, samt gamle og ny årganger av fotoblader.

  ### Medlemsavgift:
  - 350 NOK per år
  - 250 NOK per semester


  ### Medlemsfordeler:
  - Tilgang til vårt mørkerom og samlingslokale på Moholt studentby.
  - Mat og snacks på aktiviteter og turer.
  - 10% rabatt på Fotoimport AS.
  - 15% rabatt på printing fra CEWE Japan Photo.
  - 15% rabatt på alt analogt fra CEWE Japan Photo.
  - 15% rabatt på fremkalling fra CEWE Japan Photo.
`;
