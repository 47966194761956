import React from 'react';
import { sequences, state } from 'client/app.cerebral';
import { connect, ConnectedProps } from '@cerebral/react';

import { useText } from 'common/hooks';
import { Link, Wrapper } from './elements';
const deps = {
  user: state.app.user,
  loginClicked: sequences`auth.loginClicked`,
};

type Props = {
  className?: string;
};

function LoginButton({
  loginClicked,
  user,
  className,
}: Props & typeof deps & ConnectedProps): React.ReactNode {
  const t = useText();

  return (
    <Wrapper className={className}>
      {user ? (
        <Link href={'/profile'}> {user?.displayName} </Link>
      ) : (
        <Link onClick={() => loginClicked()}> {t('menu.login')} </Link>
      )}
    </Wrapper>
  );
}

export default connect(deps, LoginButton);
