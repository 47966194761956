import { Compute } from 'cerebral';
import { state } from 'client/app.cerebral';

export default Compute((get) => {
  const list = get(
    state.competition.competitions[state.competition.currentCompetition].images
  );
  const isVisible = get(
    state.competition.list[state.competition.currentCompetition].isVisible
  );
  const profile = get(state.profiles[state.app.uid]);
  const uid = get(state.app.uid);

  return Object.keys(list || {}).filter(
    (imageKey) => profile.isAdmin || isVisible || list[imageKey].uid === uid
  );
});
