export default [
  {
    path: '/',
    name: 'landing',
    signal: 'app.frontPageNavigated',
  },
  {
    path: '/join',
    name: 'joinUs',
    signal: 'app.joinUsNavigated',
  },
  {
    path: '/events',
    name: 'events',
    signal: 'app.eventsNavigated',
  },
  {
    path: '/competitions',
    name: 'competitions',
    signal: 'app.competitionsNavigated',
  },
  {
    path: '/competitions/new',
    name: 'newCompetition',
    signal: 'app.newCompetitionNavigated',
  },
  {
    path: '/competitions/:competitionKey/edit',
    name: 'editCompetition',
    signal: 'app.editCompetitionNavigated',
  },
  {
    path: '/competitions/:competitionKey',
    name: 'competition',
    signal: 'app.competitionNavigated',
  },
  {
    path: '/competitions/:competitionKey/new',
    name: 'newImage',
    signal: 'app.newImageNavigated',
  },
  {
    path: '/profile',
    name: 'profile',
    signal: 'app.profileNavigated',
  },
  {
    path: '/members',
    name: 'members',
    signal: 'app.membersNavigated',
  },
  {
    path: '/about',
    name: 'about',
    signal: 'app.aboutNavigated',
  },
  {
    path: '/kroniken',
    name: 'kroniken',
    signal: 'app.kronikenNavigated',
  },
  {
    path: '/booking',
    name: 'booking',
    signal: 'app.bookingNavigated',
  },
  {
    path: '*',
    name: 'notFound',
    signal: 'app.notFoundNavigated',
  },
];
