import type { FirebaseApp } from '@firebase/app';
import {
  getAuth,
  onAuthStateChanged,
  connectAuthEmulator,
} from 'firebase/auth';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { getDatabase, connectDatabaseEmulator } from 'firebase/database';
import { stopListening } from './src/helpers';
import getUserIDToken from './src/getUserIDToken';
import onChildAdded from './src/onChildAdded';
import onChildRemoved from './src/onChildRemoved';
import onChildChanged from './src/onChildChanged';
import onValue from './src/onValue';
import createTask from './src/createTask';
import value from './src/value';
import getDownloadURL from './src/getDownloadURL';
import signOutService from './src/signOut';
import signInWithFacebook from './src/signInWithFacebook';
import signInWithGoogle from './src/signInWithGoogle';
import deleteUser from './src/deleteUser';
import uploadAvatarImage from './src/uploadAvatarImage';
import uploadAdminImage from './src/uploadAdminImage';

export { FirebaseProviderError } from './src/errors';
export { FirebaseProviderAuthenticationError } from './src/errors';

export default function FirebaseProviderFactory({ app }: { app: FirebaseApp }) {
  const auth = getAuth(app);
  const functions = getFunctions(app);
  const storage = getStorage(app);
  const database = getDatabase(app);

  if (process.env.SKK_ENV === 'skk-dev') {
    connectAuthEmulator(auth, 'http://localhost:9099');
    connectFunctionsEmulator(functions, 'localhost', 5001);
    connectStorageEmulator(storage, 'localhost', 9199);
    connectDatabaseEmulator(database, 'localhost', 9000);
  }

  return {
    onAuthStateChanged: (
      onChange: Parameters<typeof onAuthStateChanged>[1],
      onError?: Parameters<typeof onAuthStateChanged>[2],
      onListenerRemoved?: Parameters<typeof onAuthStateChanged>[3]
    ) => onAuthStateChanged(auth, onChange, onError, onListenerRemoved),
    deleteUser: () => deleteUser(auth),
    getDownloadURL: getDownloadURL(storage),
    getUserIDToken: () => getUserIDToken(auth),
    off: stopListening,
    onChildAdded: onChildAdded(database),
    onChildChanged: onChildChanged(database),
    onChildRemoved: onChildRemoved(database),
    onValue: onValue(database),
    signInWithFacebook: () => signInWithFacebook(auth),
    signInWithGoogle: () => signInWithGoogle(auth),
    signOut: () => signOutService(auth),
    task: createTask(functions),
    value: value(database),
    uploadAvatarImage: uploadAvatarImage(auth),
    uploadAdminImage: uploadAdminImage(auth),
  };
}
