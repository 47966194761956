import React from 'react';

import { InputWrapper, InputLabel, Input, TextArea } from './elements';
import { Feedback } from '../ImageInput/elements';
import { UseFormRegister } from 'react-hook-form';

type Props = {
  className?: string;
  placeholder?: string;
  name: string;
  title?: string | React.ReactNode;
  type?: string;
  min?: string | number;
  max?: string | number;
  step?: string | number;
  required?: boolean;
  value?: string;
  error?: string | React.ReactNode;
  register: UseFormRegister<any>;
};

export default function InputComponents({
  className,
  placeholder,
  name,
  title,
  type,
  min,
  max,
  step,
  required,
  value,
  error,
  register,
}: Props): React.ReactElement {
  if (type === 'textarea') {
    return (
      <InputWrapper className={className}>
        <InputLabel>{title}</InputLabel>
        <TextArea
          value={value}
          isValid={!error}
          required={required}
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...register(name)}
        />
        <Feedback>{error}</Feedback>
      </InputWrapper>
    );
  }

  return (
    <InputWrapper className={className}>
      <InputLabel>{title}</InputLabel>
      <Input
        type={type}
        value={value}
        placeholder={placeholder}
        isValid={!error}
        checked={Boolean(value)}
        required={required}
        min={min}
        max={max}
        step={step}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...register(name)}
      />
      <Feedback>{error}</Feedback>
    </InputWrapper>
  );
}
