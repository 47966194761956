import React from 'react';
import { connect, ConnectedProps } from '@cerebral/react';
import { state } from 'client/app.cerebral';

import { Wrapper, CompetitionCard, CardContainer, Title } from './elements';

import { useText } from 'common/hooks';

type Props = {
  className?: string;
};

const deps = {
  user: state.app.user,
  active: state.competition.activeCompetitions,
  inactive: state.competition.inactiveCompetitions,
  isAdmin: state.app.isAdmin,
};

function GalleryList({
  className,
  active,
  user,
}: Props & typeof deps & ConnectedProps): React.ReactElement {
  const activeList = (Array.isArray(active) ? active : []).map((key) => (
    <CompetitionCard
      key={key}
      link={`/competitions/${key}`}
      competitionKey={key}
    />
  ));

  const t = useText();

  if (!user || activeList.length === 0) {
    return <div />;
  }

  return (
    <Wrapper className={className}>
      <Title>{t('frontPage.galleries')}</Title>
      <CardContainer>{activeList}</CardContainer>
    </Wrapper>
  );
}

export default connect<Props, typeof deps>(deps, GalleryList);
