import { initializeApp } from 'firebase/app';
import { ModuleDefinition } from 'cerebral';
import configs from 'configs/client.json';
import FirebaseProvider from 'providers/Firebase';
import RouterProvider from 'providers/Router';

import AppModule from './App';
import CompetitionModule from './Competition';
import AuthModule from './Auth';
import ProfileModule from './Profile';
import EventsModule from './Events';
import MembersModule from './Members';

import { OwnState } from './types';

const config =
  configs[(process.env.SKK_ENV as keyof typeof configs) || 'skk-dev'];

const state: OwnState = {
  language: 'no',
  profiles: {},
  urls: {},
  page: 'landing',
};

const module = {
  state,
  modules: {
    app: AppModule,
    competition: CompetitionModule,
    auth: AuthModule,
    profile: ProfileModule,
    events: EventsModule,
    members: MembersModule,
  },
  providers: {
    firebase: FirebaseProvider({
      app: initializeApp(config.firebase),
    }),
    router: RouterProvider(),
  },
};

export default module;
