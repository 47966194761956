export default {
  year: 'year',
  years: 'years',
  semester: 'semester',
  semesters: 'semesters',
  galleries: 'galleries',
  photographer: 'photographer',
  notLoggedIn: 'Log inn via the menu in order to view and upload images.',
  NotLoggedInBooking: 'Log inn via the menu in order to book equipment.',
  loadingCompetition: 'Loading gallery',
  photo: 'Photo',
  downloadImages: 'Download images',
  showImages: 'Show all images',
  spring: 'spring',
  autumn: 'autumn',
};
