import { Compute } from 'cerebral';
import { state } from 'client/app.cerebral';
import { memberDatetime } from 'utils/common';

export default Compute((get) => {
  const uid = get(state.app.uid);

  if (!uid) return { isActive: true, year: 2100, semester: 'autumn' as const };

  const profile = get(state.profiles[uid]);

  if (!profile)
    return { isActive: true, year: 2100, semester: 'autumn' as const };

  if (!profile.memberUntil)
    return { isActive: false, year: 2100, semester: 'autumn' as const };

  return {
    isActive: memberDatetime(profile.memberUntil) > Date.now(),
    ...profile.memberUntil,
  };
});
