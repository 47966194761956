import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import TableRowComponent from './TableRow';

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const TableRow = styled(TableRowComponent)`
  &:nth-child(2n) {
    background-color: #dbdbdb;
  }
`;

export const TableRowHeading = styled.tr`
  border-bottom: 2px solid gray;
`;

type TableHeadingType = {
  centered?: boolean;
};

export const TableHeading = styled.th<TableHeadingType>`
  text-align: ${({ centered }) => (centered ? 'center' : 'left')};
  padding: 10px;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'inherit')};
`;

export const Chevron = styled(FontAwesomeIcon)`
  transform: rotate(${({ direction }) => (direction === 1 ? 0 : 180)}deg);
  margin-left: 5px;
`;
