import { listenTo, createRef } from './helpers';
import { type Database } from 'firebase/database';

export default function OnValueFactory(database: Database) {
  return function OnValueFunction(
    this: any,
    path: string,
    signal: string,
    options: Parameters<typeof createRef>[2] & {
      payload?: Record<string, any>;
    } = {}
  ) {
    let hasEmittedInitialValue = false;

    listenTo(
      createRef(database, path, options),
      path,
      'value',
      signal,
      (data) => {
        if (!hasEmittedInitialValue) {
          hasEmittedInitialValue = true;

          return;
        }

        this.context.app.getSequence(signal)({
          value: data.val(),
          ...options.payload,
        });
      }
    );
  };
}
