import React from 'react';
import { connect, ConnectedProps } from '@cerebral/react';
import { state } from 'client/app.cerebral';

import ImageWrapper from 'components/ImageWrapper';
import NewCard from 'components/NewCard';
import Image from './Image';

type Props = {
  className?: string;
};

const deps = {
  competitionKey: state.competition.currentCompetition,
  competition: state.competition.list[state.competition.currentCompetition],
  isActive: state.competition.isActive,
  images: state.competition.images,
  yourImageCount: state.competition.yourUploads,
};

function Gallery({
  className,
  images,
  competition,
  competitionKey,
  isActive,
  yourImageCount,
}: Props & typeof deps & ConnectedProps): React.ReactElement {
  return (
    <ImageWrapper className={className}>
      {isActive ? (
        <NewCard
          disabled={competition.maxImages <= yourImageCount}
          link={`/competitions/${competitionKey}/new`}
        />
      ) : null}
      {images.map((imageKey) => (
        <Image key={imageKey} imageKey={imageKey} />
      ))}
    </ImageWrapper>
  );
}

export default connect(deps, Gallery);
