import { state, Context } from 'client/app.cerebral';

export function createProfile({ firebase, get }: Context) {
  return firebase.task('createProfile', { ...get(state.app.user) });
}

export function setUser({ store, props }: Context) {
  store.set(state.app.user, props.user);
}

export function logError({ props }: Context) {
  console.log(props.error); // eslint-disable-line no-console
}

export function getUser({ path, firebase }: Context) {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.onAuthStateChanged(
      (user) => {
        unsubscribe();

        if (!user) return resolve(null);

        return resolve({
          uid: user.uid,
          isAnonymous: user.isAnonymous,
          providerData: user.providerData,
          displayName: user.displayName,
          email: user.email,
          emailVerified: user.emailVerified,
          photoURL: user.providerData.some(
            (d) => d.providerId === 'facebook.com'
          ) // We don't want facebook images, as they come with tracking
            ? null
            : user.photoURL,
        });
      },
      (error) => reject(error)
    );
  })
    .then((user) => path.success({ user }))
    .catch((error) => path.error({ error }));
}

export function signInWithGoogle({ firebase, path }: Context) {
  return firebase.signInWithGoogle().then(path.success).catch(path.error);
}

export function signOut({ firebase, path }: Context) {
  return firebase.signOut().then(path.success).catch(path.error);
}

export function getProfile({ firebase, get }: Context) {
  return firebase
    .value(`profiles.${get(state.app.uid)}`)
    .then((response) => ({ profile: response.value }));
}

export function setProfile({ store, props, get }: Context) {
  const uid = get(state.app.uid);

  if (uid) {
    store.set(state.profiles[uid], props.profile);
  }
}
