import React from 'react';
import { connect, ConnectedProps } from '@cerebral/react';
import { state, sequences } from 'client/app.cerebral';

import { Wrapper, Image } from './elements';

type Props = {
  className?: string;
};

const deps = {
  imagePath: state.app.overlayImagePath,
  imageClicked: sequences`app.imageClicked`,
};

function ImageOverlay({
  className,
  imagePath,
  imageClicked,
}: Props & typeof deps & ConnectedProps): React.ReactNode {
  if (!imagePath) return null;

  return (
    <Wrapper
      className={className}
      role={'button'}
      tabIndex={0}
      onClick={() => imageClicked({ path: '' })}
      onKeyPress={(event) => {
        if (event.key === 'Enter') {
          return imageClicked({ path: '' });
        }

        return null;
      }}
    >
      <Image noClick path={imagePath} />
    </Wrapper>
  );
}

export default connect<Props, typeof deps>(deps, ImageOverlay);
