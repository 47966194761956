import { httpsCallable, type Functions } from 'firebase/functions';
import { FirebaseProviderError } from './errors';

/**
 * Run a low-resource task
 * @param functions
 * @returns {function(*, *=): Promise<T>}
 */
export default function createTask(functions: Functions) {
  return (name: string, payload: Record<string, any> = {}) => {
    return httpsCallable(
      functions,
      name
    )(payload)
      .then((result) => result.data)
      .catch((error) => {
        throw new FirebaseProviderError(error);
      });
  };
}
