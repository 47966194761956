import computeActiveCompetitions from 'computed/computeActiveCompetitions';
import computeInactiveCompetitions from 'computed/computeInactiveCompetitions';
import computeCompetitionInfo from 'computed/computeCompetitionInfo';
import computeIsActive from 'computed/computeIsActive';
import computeCompetitionImages from 'computed/computeCompetitionImages';
import computeYourUploads from 'computed/computeYourUploads';
import computeSlideImage from 'computed/computeSlideImage';

import * as sequences from './sequences';

import { OwnState } from './types';

const state: OwnState = {
  list: {},
  info: computeCompetitionInfo,
  isActive: computeIsActive,
  images: computeCompetitionImages,
  yourUploads: computeYourUploads,
  slideImage: computeSlideImage,
  competitions: {},
  activeCompetitions: computeActiveCompetitions,
  inactiveCompetitions: computeInactiveCompetitions,
  slideIndex: 0,
  isPresentation: true,
  currentCompetition: '',
};

export default {
  state,
  sequences: {
    removeImageConfirmClicked: sequences.removeImageConfirmClicked,
    removeImageClicked: sequences.removeImageClicked,
    removeCompetitionConfirmClicked: sequences.removeCompetitionConfirmClicked,
    removeCompetitionClicked: sequences.removeCompetitionClicked,
    competitionRouted: sequences.competitionRouted,
    competitionsRouted: sequences.competitionsRouted,
    competitionVisibilityClicked: sequences.toggleCompetitionVisibility,
    competitionVisibilityChanged: sequences.competitionVisibilityChanged,
    competitionPresentationClicked: sequences.toggleCompetitionPresentation,
    badgeSelected: sequences.setBadge,
    imageAdded: sequences.addImage,
    imageChanged: sequences.changeImage,
    imageRemoved: sequences.removeImage,
    arrowRightClicked: sequences.nextSlide,
    arrowLeftClicked: sequences.previousSlide,
  },
};
