import React, { Component } from 'react';
import { connect } from '@cerebral/react';
import { state, sequences, props } from 'client/app.cerebral';

import { ImageComponent } from './elements';

type Props = {
  className?: string;
  path: string;
  onClick?: ({ path }: { path: string }) => void;
};

const deps = {
  url: state.urls[props`path`],
  addUrl: sequences`app.addUrl`,
};

class ImageHandler extends Component<Props & typeof deps> {
  componentDidMount() {
    if (!this.props.url) {
      this.props.addUrl({
        path: this.props.path,
      });
    }
  }

  componentWillUpdate() {
    if (!this.props.url) {
      this.props.addUrl({
        path: this.props.path,
      });
    }
  }

  handleKeyPressed(event: React.KeyboardEvent<HTMLDivElement>): void {
    if (event.key === 'Enter' && this.props.onClick) {
      this.props.onClick({ path: this.props.path });
    }
  }

  render() {
    return (
      <ImageComponent
        // eslint-disable-next-line
        {...(this.props.onClick
          ? {
              onKeyPress: (e) => this.handleKeyPressed(e),
              onClick: () => this.props.onClick?.({ path: this.props.path }),
              tabIndex: 0,
              role: 'button',
            }
          : {})}
        className={this.props.className}
        backgroundImage={this.props.url}
      />
    );
  }
}

export default connect(deps, ImageHandler);
