export default {
  title: 'Studentenes Kameraklubb',
  landing: 'Home',
  about: 'About',
  joinUs: 'Join',
  contact: 'Contact',
  login: 'Log In',
  logout: 'Log Out',
  competitions: 'Pictures',
  profile: 'My Profile',
  events: 'Events',
  members: 'Members',
  booking: 'Booking',
};
