import styled from 'styled-components';

type ImageComponentProps = {
  backgroundImage: string;
};

export const ImageComponent = styled.div<ImageComponentProps>`
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-color: #eee;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;
