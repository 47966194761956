import React from 'react';
import { connect, ConnectedProps } from '@cerebral/react';
import { state } from 'client/app.cerebral';

import { Wrapper, Loading, Event, Link, Title } from './elements';

import { useText } from 'common/hooks';

type Props = {
  className?: string;
};

const deps = {
  eventsList: state.events.eventsList,
  loadingEvents: state.events.loadingEvents,
};

function EventList({
  className,
  eventsList,
  loadingEvents,
}: Props & typeof deps & ConnectedProps): React.ReactElement {
  const t = useText();

  if (loadingEvents) {
    return (
      <Wrapper className={className}>
        <Title>{t('frontPage.events')}</Title>
        <Loading>{t('events.loadingEvents')}</Loading>
      </Wrapper>
    );
  }
  if (eventsList.length === 0) {
    return (
      <Wrapper className={className}>
        <Title>{t('frontPage.events')}</Title>
        <Loading>{t('events.noUpcomingEvents')}</Loading>
      </Wrapper>
    );
  }
  const eventListTooLong = eventsList.length > 3;
  let dispEventsList = eventsList;
  if (eventListTooLong) {
    dispEventsList = eventsList.slice(0, 3);
  }

  return (
    <Wrapper className={className}>
      <Title>{t('frontPage.events')}</Title>
      {dispEventsList.map((event) => (
        <Event key={event.id} event={event} />
      ))}
      {eventListTooLong ? (
        <Link href={'/events'}>{t('frontPage.moreEvents')}</Link>
      ) : null}
    </Wrapper>
  );
}

export default connect(deps, EventList);
