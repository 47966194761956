import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Wrapper = styled.div`
  padding: 14px;
  display: flex;
  @media (max-width: 768px) {
    padding: 14px 0;
  }
`;

export const Timeline = styled.div`
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
`;

export const DateLabel = styled.p`
  flex-grow: 0;
  margin: 10px 0;
  color: #ee2642;
  font-weight: bold;
`;

export const TimeLabel = styled.p`
  flex-grow: 0;
  text-align: center;
  margin: 5px;
`;

export const TimeWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

export const Spacer = styled.div`
  flex-grow: 1;
  border-right: 1px solid gray;
  align-self: center;
  flex-basis: 10px;
`;

export const Title = styled.h3`
  margin: 0;
`;

export const Line = styled.div`
  border-top: 1px solid gray;
  margin-bottom: 4px;
  width: 100%;
`;

export const Description = styled.p`
  white-space: pre-wrap;
  margin-bottom: 0;
`;

export const Location = styled.p`
  margin: 0;
  align-self: center;
`;

export const Info = styled.div`
  flex-grow: 1;
`;

export const Marker = styled(FontAwesomeIcon)`
  font-size: 1rem;
  margin: 4px 4px 4px 0;
  align-self: center;
  color: #ee2642;
`;

export const LocationWrapper = styled.div`
  display: flex;
`;

export const EventLink = styled.a`
  color: #ee2642;
  text-decoration: none;
  font-weight: bold;
  transition: opacity 0.2s ease-in-out;
  &:hover {
    opacity: 0.8;
  }
`;

export const Link = styled.a`
  color: #ee2642;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  &:hover {
    opacity: 0.8;
  }
`;
