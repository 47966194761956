import React from 'react';

import { useText } from 'common/hooks';

import {
  Header,
  Image,
  ImageWrapper,
  Paragraph,
  SubHeader,
  Wrapper,
  Link,
  PeopleGrid,
  Title,
  NameEmail,
} from './elements';

export default function About() {
  const t = useText();

  return (
    <Wrapper>
      <Header>{t('about.title')}</Header>
      <Paragraph>{t('about.firstParagraph')}</Paragraph>
      <ImageWrapper>
        <Image src={'/images/about/SK004.jpg'} />
        <Image src={'/images/about/SK015.jpg'} />
        <Image src={'/images/about/SK019.jpg'} />
      </ImageWrapper>
      <SubHeader>{t('about.history')}</SubHeader>
      <Paragraph>{t('about.secondParagraph')}</Paragraph>
      <Link href={'https://foto.samfundet.no/informasjon/historie'}>
        {t('about.linkFG')}
      </Link>
      <SubHeader>{t('about.board')}</SubHeader>
      <PeopleGrid>
        <Title>{t('about.leder')}</Title>
        <NameEmail>
          <p>{'Iver Søbakk'}</p>
          <a href="mailto:leder@studenteneskameraklubb.no">
            {'leder@studenteneskameraklubb.no'}
          </a>
        </NameEmail>
        <Title>{t('about.nestleder')}</Title>
        <NameEmail>
          <p>{'Steen Andersen'}</p>
          <a href="mailto:nestleder@studenteneskameraklubb.no">
            {'nestleder@studenteneskameraklubb.no'}
          </a>
        </NameEmail>
        <Title>{t('about.okonomi')}</Title>
        <NameEmail>
          <p>{'Philip Draget'}</p>
          <a href="mailto:okonomi@studenteneskameraklubb.no">
            {'okonomi@studenteneskameraklubb.no'}
          </a>
        </NameEmail>
        <Title>{t('about.kjeller')}</Title>
        <NameEmail>
          <p>{'Andreas Tranvåg'}</p>
          <a href="mailto:kjeller@studenteneskameraklubb.no">
            {'kjeller@studenteneskameraklubb.no'}
          </a>
        </NameEmail>
        <Title>{t('about.kroniken')}</Title>
        <NameEmail>
          <p>{'Elena Kirchman'}</p>
          <a href="mailto:kroniken@studenteneskameraklubb.no">
            {'kroniken@studenteneskameraklubb.no'}
          </a>
        </NameEmail>
        <Title>{t('about.arrangementer')}</Title>
        <NameEmail>
          <p>{'Casper Draget'}</p>
          <a href="mailto:arreagementer@studenteneskameraklubb.no">
            {'arrangementer@studenteneskameraklubb.no'}
          </a>
        </NameEmail>
        <Title>{t('about.itkom')}</Title>
        <NameEmail>
          <p>{'Julia Stensnes-Martini'}</p>
          <a href="mailto:itkom@studenteneskameraklubb.no">
            {'itkom@studenteneskameraklubb.no'}
          </a>
        </NameEmail>
      </PeopleGrid>
      <Image
        src={'/images/about/styret.jpg'}
        style={{ width: '80%', margin: '1rem' }}
      />
      <SubHeader>{t('about.statutes')}</SubHeader>
      <Link
        href={
          'https://drive.google.com/file/d/1Y2w9H-YBus0ScESzSNJJVoIaYD03NIj6/view?usp=drive_link'
        }
      >
        {t('about.statutesLink')}
      </Link>
    </Wrapper>
  );
}
