import * as cerebral from 'cerebral';
import firebase from 'providers/Firebase';
import router from 'providers/Router';

import { State } from '../modules/types';

type Sequences = any;

export type Providers = {
  firebase: ReturnType<typeof firebase>;
  router: ReturnType<typeof router>;
};

export type Context<Props = Record<string, never>> = cerebral.IContext<Props> &
  Providers;
export const { props } = cerebral;
export const state = cerebral.state as unknown as State;
export const { string } = cerebral;
export const { sequences } = cerebral;
// Temporarily used for typing sequences. The cerebral babel plugin requires certain names, so I'm high jacking this
export const moduleSequences = cerebral.sequences as unknown as Sequences;
export const { moduleState } = cerebral;
