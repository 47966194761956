import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ImageComponent from 'common/Image';

export const Wrapper = styled.div`
  min-height: calc(100vh - 40px);
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 20px;
  left: 0px;
  padding: 20px;
`;

export const Title = styled.h1`
  font-size: 40px;
  line-height: 40px;
  margin: 0;
  text-align: center;
`;

export const Image = styled(ImageComponent)`
  width: 100%;
  flex-grow: 1;
`;

export const Photographer = styled.p`
  text-align: center;
`;

export const ImageCounter = styled.p`
  position: absolute;
  bottom: 0;
  right: 0;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 6px;
`;

type ArrowProps = {
  disabled?: boolean;
};

export const Arrow = styled(FontAwesomeIcon)<ArrowProps>`
  font-size: 40px;
  line-height: 40px;
  color: ${(props) => (props.disabled ? '#c3c3c3' : '#222')};
  cursor: ${(props) => (props.disabled ? 'inherit' : 'pointer')};
`;

export const TitleWrapper = styled.div``;

export const Icon = styled(FontAwesomeIcon)`
  position: fixed;
  top: 0;
  right: 0;
  margin: 0.5em;
  margin-bottom: 1px;
  font-size: 2em;
  color: gray;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  &:hover {
    color: red;
  }
`;
