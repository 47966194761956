import styled from 'styled-components';

import CompetitionCardComponent from './CompetitionCard';

export const Wrapper = styled.div`
  margin: 20px;
`;

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-gap: 10px;

  @media (max-width: 768px) {
    grid-template-columns: 100%;
  }
`;

export const CompetitionCard = styled(CompetitionCardComponent)`
  width: 100%;
  margin: 10px auto;
`;

export const SectionHeader = styled.h2`
  text-align: center;
`;

export const Title = styled.h2`
  margin: 0;
  padding: 0;
  text-align: left;
`;
