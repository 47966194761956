import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 80%;
  @media (max-width: 768px) {
    width: 80%;
  }
`;
export const Header = styled.h1`
  font-size: 4rem;
  margin: 0 auto;
  padding: 0.4em;
  margin-bottom: 1rem;
  text-align: center;
  font-weight: 800;
  width: fit-content;
  background-image: linear-gradient(to right, #ee2642 50px, transparent 50px),
    linear-gradient(to right, #ee2642 50px, transparent 50px),
    linear-gradient(to bottom, #ee2642 50px, transparent 50px),
    linear-gradient(to bottom, #ee2642 50px, transparent 50px);
  background-size: 100% 5px, 100% 5px, 5px 100%, 5px 100%;
  background-position: -25px 0%, -25px 100%, 0% -25px, 100% -25px;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  @media (max-width: 768px) {
    font-size: 4rem;
  }
`;
export const NotLoggedIn = styled.h4`
  text-align: center;
  padding-top: 25px;
`;
