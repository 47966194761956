import React from 'react';
import { connect, ConnectedProps } from '@cerebral/react';
import { sequences } from 'cerebral';

import { Wrapper, Header, InnerWrapper } from './elements';
import Input from 'common/Input';
import SubmitButton from 'common/SubmitButton';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useText } from 'common/hooks';
import { yupResolver } from '@hookform/resolvers/yup';

const schema = yup.object().shape({
  name: yup.string().required('errors.required'),
  message: yup.string().required('errors.required'),
  email: yup.string().required('errors.required').email('errors.email'),
});

type Props = {
  className?: string;
};

const deps = {
  sendMessage: sequences`app.sendMessage`,
};

function Message({
  className,
  sendMessage,
}: Props & typeof deps & ConnectedProps): React.ReactElement {
  const t = useText();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm<yup.InferType<typeof schema>>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  return (
    <Wrapper className={className}>
      <InnerWrapper>
        <span>
          <Header>{t('frontPage.message.title')}</Header>
          <form
            onSubmit={handleSubmit((data) => {
              reset();

              return sendMessage({ data });
            })}
          >
            <Input
              type={'text'}
              name={'name'}
              title={t('frontPage.message.form.name.title')}
              required
              register={register}
              error={errors.name?.message ? t(errors.name.message) : null}
              placeholder={
                t('frontPage.message.form.name.placeholder') as string
              }
            />
            <Input
              type={'email'}
              name={'email'}
              title={t('frontPage.message.form.email.title')}
              required
              register={register}
              error={errors.email?.message ? t(errors.email.message) : null}
              placeholder={
                t('frontPage.message.form.email.placeholder') as string
              }
            />
            <Input
              type={'textarea'}
              name={'message'}
              title={t('frontPage.message.form.message.title')}
              required
              register={register}
              error={errors.message?.message ? t(errors.message.message) : null}
            />
            <SubmitButton type={'submit'} disabled={!isValid}>
              {t('frontPage.message.form.submit')}
            </SubmitButton>
          </form>
        </span>
      </InnerWrapper>
    </Wrapper>
  );
}

export default connect<Props, typeof deps>(deps, Message);
