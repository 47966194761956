import React from 'react';
import { connect, ConnectedProps } from '@cerebral/react';
import { state, props, sequences } from 'client/app.cerebral';

import ImageCard from 'components/ImageCard';

import { useText } from 'common/hooks';

type Props = {
  className?: string;
  imageKey: string;
};

const deps = {
  image: state.profile.images[props`imageKey`],
  profile: state.app.profile,
  uid: state.app.uid,
  removeImageClicked: sequences`competition.removeImageClicked`,
};
function Image({
  className,
  image,
  imageKey,
  profile,
  uid,
  removeImageClicked,
}: Props & typeof deps & ConnectedProps): React.ReactElement {
  const t = useText();

  return (
    <ImageCard
      className={className}
      path={image.imageUrl}
      badge={image.badge}
      showRemove={profile?.isAdmin || image.uid === uid}
      onClickRemove={() =>
        removeImageClicked({
          imageKey: imageKey,
          competitionKey: image.competitionKey,
        })
      }
      title={image.title}
      description={`${t('common.photo')}: ${profile?.name}`}
    />
  );
}

export default connect<Props, typeof deps>(deps, Image);
