export default `MARKDOWN:
  Throughout the semester, we organise an array of courses that encompass topics 
  such as introductory DSLR techniques, digital editing, analog photography, and 
  development of analog film. Additionally, we host photowalks in Trondheim, during 
  which participants capture images together while exploring the city. These 
  photographs are later discussed, and a selection is showcased on our Facebook page, 
  as well as uploaded to our website for public viewing. 

  We also arrange photo-beer events at various locations, along with engaging 
  discussions, informative lectures and presentations, and numerous other activities 
  for those passionate about capturing both the subtle and grand moments of life.
`;
