import React from 'react';
import { connect, ConnectedProps } from '@cerebral/react';
import { state } from 'client/app.cerebral';

import {
  Wrapper,
  CompetitionCard,
  CardContainer,
  SectionHeader,
  NewCompetitionCard,
  NotLoggedIn,
} from './elements';

import { useText } from 'common/hooks';

type Props = {
  className?: string;
};

const deps = {
  user: state.app.user,
  active: state.competition.activeCompetitions,
  inactive: state.competition.inactiveCompetitions,
  isAdmin: state.app.isAdmin,
};

function Competitions({
  className,
  active,
  inactive,
  isAdmin,
  user,
}: Props & typeof deps & ConnectedProps): React.ReactElement {
  const activeList = (Array.isArray(active) ? active : []).map((key) => (
    <CompetitionCard
      key={key}
      link={`/competitions/${key}`}
      competitionKey={key}
    />
  ));
  const inactiveList = (Array.isArray(inactive) ? inactive : []).map((key) => (
    <CompetitionCard
      key={key}
      link={`/competitions/${key}`}
      competitionKey={key}
    />
  ));

  const t = useText();

  if (!user) {
    return (
      <Wrapper className={className}>
        <NotLoggedIn>{t('common.notLoggedIn')}</NotLoggedIn>
      </Wrapper>
    );
  }

  return (
    <Wrapper className={className}>
      <SectionHeader>{t('images.gallery.active')}</SectionHeader>
      <CardContainer>
        {isAdmin ? <NewCompetitionCard link={'/competitions/new'} /> : null}
        {activeList}
      </CardContainer>
      <SectionHeader>{t('images.gallery.inactive')}</SectionHeader>
      <CardContainer>{inactiveList}</CardContainer>
    </Wrapper>
  );
}

export default connect<Props, typeof deps>(deps, Competitions);
