import 'core-js';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import controller from './controller';

const rootElement = document.querySelector('#app');

if (rootElement) {
  createRoot(rootElement).render(<App controller={controller} />);
}
