import React from 'react';
import { state, sequences } from 'client/app.cerebral';
import { connect, ConnectedProps } from '@cerebral/react';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';

import { useText } from 'common/hooks';

import {
  Table,
  TableRow,
  TableRowHeading,
  TableHeading,
  Chevron,
} from './elements';

type Props = {
  className?: string;
};

const deps = {
  members: state.members.list,
  sortedBy: state.members.sortBy,
  sortDirection: state.members.sortDirection,
  sortBy: sequences`members.sortBy`,
};

function TableComponent({
  className,
  members,
  sortBy,
  sortedBy,
  sortDirection,
}: Props & typeof deps & ConnectedProps): React.ReactElement {
  const t = useText();

  return (
    <Table className={className}>
      <thead>
        <TableRowHeading>
          <TableHeading onClick={() => sortBy({ by: 'name' })}>
            {t('members.table.name')}
            {sortedBy === 'name' && (
              <Chevron icon={faChevronUp} direction={sortDirection} />
            )}
          </TableHeading>
          <TableHeading onClick={() => sortBy({ by: 'email' })}>
            {t('members.table.email')}
            {sortedBy === 'email' && (
              <Chevron icon={faChevronUp} direction={sortDirection} />
            )}
          </TableHeading>
          <TableHeading onClick={() => sortBy({ by: 'emails' })}>
            {t('members.table.emails')}
            {sortedBy === 'emails' && (
              <Chevron icon={faChevronUp} direction={sortDirection} />
            )}
          </TableHeading>
          <TableHeading onClick={() => sortBy({ by: 'terms' })}>
            {t('members.table.terms')}
            {sortedBy === 'terms' && (
              <Chevron icon={faChevronUp} direction={sortDirection} />
            )}
          </TableHeading>
          <TableHeading onClick={() => sortBy({ by: 'isAdmin' })} centered>
            {t('members.table.isAdmin')}
            {sortedBy === 'isAdmin' && (
              <Chevron icon={faChevronUp} direction={sortDirection} />
            )}
          </TableHeading>
          <TableHeading onClick={() => sortBy({ by: 'memberUntil' })}>
            {t('members.table.memberUntil.title')}
            {sortedBy === 'memberUntil' && (
              <Chevron icon={faChevronUp} direction={sortDirection} />
            )}
          </TableHeading>
          <TableHeading>{t('members.table.newPayment')}</TableHeading>
        </TableRowHeading>
      </thead>
      <tbody>
        {(Array.isArray(members) ? members : []).map(({ uid }) => (
          <TableRow key={uid} uid={uid} />
        ))}
      </tbody>
    </Table>
  );
}

export default connect<Props, typeof deps>(deps, TableComponent);
