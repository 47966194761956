import React from 'react';
import { connect, ConnectedProps } from '@cerebral/react';
import { sequences } from 'cerebral';

import ImageHandler from '../ImageHandler';

type Props = {
  className?: string;
  path: string;
  alt: string;
  url: string;
  noClick?: boolean;
  onClick?: ({ path }: { path: string }) => void;
};

const deps = {
  imageClicked: sequences`app.imageClicked`,
};

function Image({
  className,
  path,
  alt,
  url,
  noClick,
  onClick,
  imageClicked,
}: Props & typeof deps & ConnectedProps): React.ReactElement | null {
  if (!path) return null;

  const pathWithoutExtension = path.substring(0, path.length - 4);

  return (
    <ImageHandler
      className={className}
      alt={alt}
      url={url}
      path={pathWithoutExtension}
      // eslint-disable-next-line
      {...(!noClick
        ? {
            onClick: onClick
              ? onClick
              : () => imageClicked({ path: pathWithoutExtension }),
          }
        : {})}
    />
  );
}

export default connect(deps, Image);
