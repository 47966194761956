export default {
  title: 'Vilkår og personvern',
  accept: 'Aksepter',
  welcomeHeading: 'Velkommen til Studentenes Kameraklubb',
  welcome: `Studentenes Kameraklubb, SK, er en studentorganisasjon med hensikt å inspirere og dyrke fotografimiljøet for studenter i Trondheim. Denne nettsiden fungerer som et felles samlingspunkt for medlemmer hvor informasjon og bilder fra medlemmene gjørestilgjengelige.

Behandlingsansvarlig for dine personopplysninger er Studentenes Kameraklubb i Trondheim, org.nr. 889 424 842, tilbyder av tjenestene. Denne personvernerklæringen kan referere til “deg”, og med “deg” mener vi besøkende av våre nettsider, bruker av en eller flere av våre tjenester og/eller bruker via våre kunder. Denne personvernerklæringen gjelder ikke for eksternenettsteder, produkter eller tjenester.

Vi oppdaterer denne personvernerklæringen med jevne mellomrom, og publiserer alle endringer her, men dersom endringene er betydningsfulle, kommer vi til å sørge for å sende ut et varsel om dette. 
`,
  introductionHeading: 'Introduksjon',
  introduction: `Personlig informasjon er all informasjon du frivillig gir fra deg til oss og som kan identifisere deg som person, inkludert kontaktinformasjon, slik som navn, e-post adresse, navn på virksomhet og annen informasjon om deg selv. Dette inkluderer også bilder som blir sendt inn til våre album som avbilder gjenkjennelige personer.

Du gir oss personlig informasjon ved å logge inn gjennom Google, når du oppdaterer profilen din og laster opp bilder som kategoriseres som personlig informasjon. Formålet med informasjonen er å ha et medlemsregister, samt ha muligheten til å la medlemmer laste opp bilder til albumer og konkurranser.`,
  whatHeading: 'Hva gjør vi med informasjonen? ',
  what: `Vi selger den ikke. Vi bruker din personlige informasjon til å produsere aggregert innsikt og statistikk som ikke identifiserer deg eller noen du kjenner som person. Disse innsiktene brukes i rapporter, presentasjoner, case studier, på nettsidene våre, i sosiale medier, i blogginnlegg og lignende. I tillegg kan vi bruke personlig informasjon til å forbedre nettleseropplevelsen ved å personliggjøre våre nettsider samt forbedre våre tjenester, kommunisere og sende deg informasjon eller innhold som vi tror vil motivere deg i samsvar med innstillingene for dine kommunikasjonspreferanser og respondere på henvendelser om brukerstøtte.`,
  storageHeading: 'Oppbevaring av personlig informasjon',
  storage: `Hvor lenge vi oppbevarer informasjonen vi samler om deg avhenger av typen informasjon og formålet for behandling. Vi oppbevarer personlig informasjon om deg så lenge vi har et pågående forhold med deg. 

Hvis du oppretter en profil hos oss, anser vi forholdet som pågående og oppbevarer informasjon om deg frem til du velger å slette din profil. Velger du å slette din profil, sletter vi og anonymiserer din personlige informasjon. `,
  emailHeading: 'E-post',
  email: `Oppretter du en konto på org.ntnu.no/sk, abonnerer på en av våre e-post lister og/eller bruker en av våre tjenester kan du motta e-post fra oss i samsvar med dine kommunikasjonspreferanser.

I hver e-post du mottar fra oss kan du velge å melde deg av e-post listen på bunnen av hver e-post. Du kan også velge og endre innstillinger for kommunikasjon under din profil. `,
  childrenHeading: 'Barn under 15 år',
  children: `Våre nettsider er ikke ment for eller målrettet mot barn under 15 år, og vi samler ikke bevisst eller forsettlig inn personlige informasjon om barn. Barn under 15 år kan bruke SK sine tjenester, men bare sammen med eller under tilsyn av sine foreldre eller formynder.`,
  personalHeading: 'Sikkerhet av din personlige informasjon ',
  personal: `Vi sikrer personlig informasjon du gir oss på dataservere i et kontrollert, sikkert område, beskyttet fra urettmessig tilgang, bruk og/eller avsløring. All personlig informasjon er beskyttet ved bruk av tilstrekkelig fysisk, teknisk og organisatoriske tiltak.

En SK-konto krever en bruker hos Google. Du er selv ansvarlig for å holde ditt brukernavn og passord hemmelig, og ikke avsløre det til en tredjepart. `,
  linksHeading: 'Link til eksterne nettsider',
  links: `Våre nettsider inneholder linker til andre nettsider som for eksempel www.sit.no. Disse sidene har egne personvernerklæringer og vi anbefaler deg at du leser disse nøye slik du også har gjort vår.`,
  consentHeading: 'Samtykke',
  consent: `Det er frivillig å gi personlig informasjon til våre tjenester som å opprette en profil, laste opp bilder og motta e-post. For å kunne opprette en profil hos SK må du akseptere denne personvernerklæringen. `,
  erasingHeading: 'Sletting',
  erasing: `Som SK-bruker har du rett til å slette din profil til enhver tid og med det ha all personlig informasjon om deg selv slettet. Hvis du ønsker din informasjon slettet bes du kontakte IT-ansvarlig i SK.
  
På grunn av sikkerhetskopier, kan det ta opptil 180 dager for dataen til å være fullstendig slettet fra Google Cloud Platform. Ønsker du å opprette en ny profil hos SK vil du få en ny bruker-ID koblet til profilen din. Vi kan ikke hente opp igjen informasjon om deg, eller linke en gammel profil til en ny profil.`,
  transferHeading: 'Databehandlere og overføring til utlandet',
  transfer: `Vi engasjerer underleverandører for å utføre tjenester på våre vegne og/eller for å assistere leveringen av tjenester til deg.

EU-US Privacy Shield rammeverket tredde i kraft 12.Juli 2016, og sikrer et tilstrekkelig sikkerhetsnivå ved overføring av personlig informasjon fra Europa til USA. Som et resultat av EØS-avtalen er Privacy Shield brukt som et grunnlag for overføring av personlig informasjon fra norske selskaper til USA. Alle SKs underleverandører er underlagt Privacy Shield rammeverket.
  
Vårt hovedkontor ligger i Trondheim, Norge, men din personlige informasjon kan bli overført til, lagret og/eller behandlet i USA. Dersom vi tilgjengeliggjør personlig informasjon til tredjeparter sørger vi for et tilstrekkelig sikkerhetsnivå og avtaler for å hindre at tredjeparter bruker personlig informasjon til andre formål enn avtalt.`,
  getHeading: 'Tilgjengeliggjøring av personlig informasjon',
  get: `Vi kan tilby konkurranser hvor vinnere blir tildelt. For at vi skal kunne annonsere vinnere kan vi dele ditt navn og/eller kontaktinformasjon samt opplastede bilder med ansvarlig for sosiale medier i SK.`,

  dueHeading: 'Forpliktelse til konfidensialitet',
  due: `Alle i SKs styre og personer med verv må underskrive og følge konfidensialitetsavtalen. Kun personer som har behov for innsyn til din personlige informasjon for å gjøre jobben sin får innvilget tilgang og sørger for et høyt beskyttelsesnivå. Generelle retningslinjer er at alle medlemmer i styret og vervinnehavere må underskrive og følge konfidensialitetsavtalen, kun autoriserte i styret får tilgang til personlige informasjon og alle i SK praktiserer en kultur for personvernbeskyttelse.`,
  useHeading: 'Hvordan utøve dine rettigheter?',
  use: `Du har rett til å be om tilgang på din personlige informasjon, retting av personlig informasjon, oppdatere personlig informasjon, slette utdatert eller uriktig personlig informasjon vi har lagret om deg. Mange endringer kan du selv gjøre under innstillinger i din profil, men du kan også kontakte SKs styre for å utøve dine rettigheter.`,
  contactHeading: 'Kontakt SK',
  contact: `SKs styre kan kontaktes på mail@studenteneskameraklubb.no.
  
E-post kommunikasjon er ikke alltid helt sikkert, og vi ber deg derfor om å ikke sende oss sensitiv informasjon om deg selv (rasemessig eller etnisk opprinnelse, politiske meninger, medlemskap i politisk parti, religiøs tro og overbevisning, filosofisk tro, seksuelle preferanser eller kriminelt rulleblad). `,
};
