import { Context, state } from 'client/app.cerebral';

export function updateMemberUntil({ firebase, get, path }: Context) {
  return firebase
    .task('updateMemberUntil', {
      uidToUpdate: get(state.members.edit.uid),
      semester: get(state.members.edit.semester),
      year: get(state.members.edit.year),
    })
    .then(path.success)
    .catch(path.error);
}

export function sendWelcomeEmail({ firebase, props, path }: Context) {
  return firebase
    .task('sendWelcomeEmail', {
      uidToSendTo: props.uid,
    })
    .then(path.success)
    .catch(path.error);
}

export function sendInfoEmail({ firebase, props, path }: Context) {
  return firebase
    .task('sendInfoEmail', {
      uidToSendTo: props.uid,
    })
    .then(path.success)
    .catch(path.error);
}

export function invertSort({ get, store }: Context) {
  return store.set(
    state.members.sortDirection,
    get(state.members.sortDirection) * -1
  );
}

export function getAllProfiles({ firebase, path }: Context) {
  return firebase
    .value('profiles')
    .then((response) => path.success({ profiles: response.value || {} }))
    .catch((error) => path.error({ error }));
}

export function onProfileChanged({ firebase }: Context) {
  return firebase.onChildChanged(`profiles`, `members.memberChanged`);
}
