export default `MARKDOWN:
  I løpet av studieåret holder vi diverse kurs innenfor temaer som introduksjon
  til speilreflekskamera, bilderedigering, analog fotografering og fremkalling
  i mørkerom. Innimellom samles vi for fototur i Trondheim by, hvor vi går rundt
  og knipser bilder sammen. Aktivitetene avsluttes med kåring av dagens beste
  bilde som blir deretter publisert på vår nettside og facebook hvor alle kan
  nyte det.

  Fotopils på Antikvariatet, kosekveld, diskusjonforum, foredrag og mye, mye mer for deg som
  synes at det er artig å fange verden i det ene lille øyeblikket.
`;
