import { Compute } from 'cerebral';
import { state } from 'client/app.cerebral';

export default Compute((get) => {
  const competitions = get(state.competition.competitions);
  const currentCompetition = get(state.competition.currentCompetition);
  const uid = get(state.app.uid);

  if (competitions[currentCompetition]) {
    return Object.keys(competitions[currentCompetition].images || {}).filter(
      (imageKey) =>
        competitions[currentCompetition].images[imageKey].uid === uid
    ).length;
  }

  return 0;
});
