import styled from 'styled-components';

export const InputWrapper = styled.div`
  margin-bottom: 1rem;
`;

export const InputLabel = styled.label`
  display: inline-block;
  margin-bottom: 0.5rem;
`;

type IsValidProps = {
  isValid?: boolean;
};

export const Input = styled.input<IsValidProps>`
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid ${({ isValid }) => (isValid ? '#ee2642' : 'red')};
  border-radius: 0.25rem;
  margin: 0;
  overflow: show;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

export const TextArea = styled.textarea<IsValidProps>`
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid ${({ isValid }) => (isValid ? '#ee2642' : 'red')};
  border-radius: 0.25rem;
  margin: 0;
  overflow: show;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  resize: vertical;
`;
