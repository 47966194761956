import React from 'react';
import { connect, ConnectedProps } from '@cerebral/react';
import { state, sequences, props } from 'client/app.cerebral';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import {
  Wrapper,
  Header,
  HeaderWrapper,
  Remove,
  Image,
  Description,
  Link,
} from './elements';

type Props = {
  className?: string;
  competitionKey: string;
  link: string;
};

const deps = {
  isAdmin: state.app.isAdmin,
  competition: state.competition.list[props`competitionKey`],
  removeCompetitionClicked: sequences`competition.removeCompetitionClicked`,
};

function CompetitionCard({
  className,
  isAdmin,
  competition,
  competitionKey,
  removeCompetitionClicked,
  link,
}: Props & typeof deps & ConnectedProps): React.ReactElement {
  return (
    <Wrapper className={className}>
      <HeaderWrapper>
        <Link href={link}>
          <Header title={competition.title}>{competition.title}</Header>
        </Link>
        {isAdmin ? (
          <Remove
            tabIndex={0}
            role={'button'}
            icon={faTimesCircle}
            onClick={(e) => {
              e.stopPropagation();

              return removeCompetitionClicked({ competitionKey });
            }}
          />
        ) : null}
      </HeaderWrapper>
      <Link href={link}>
        <Image path={competition.imageUrl} noClick />
      </Link>
      <Description>{competition.description}</Description>
    </Wrapper>
  );
}

export default connect<Props, typeof deps>(deps, CompetitionCard);
