import { set, when } from 'cerebral/operators';
import { state, props } from 'client/app.cerebral';

import * as actions from './actions';

export const signIn = [
  actions.signInWithGoogle,
  {
    error: [actions.logError],
    success: [
      actions.getUser,
      {
        error: [],
        success: [set(state.app.user, props`user`), () => location.reload()],
      },
    ],
  },
];

export const signOut = [
  actions.signOut,
  {
    error: [],
    success: [
      actions.getUser,
      {
        error: [],
        success: [
          set(state.app.user, props`user`),
          () => location.replace('/'),
        ],
      },
    ],
  },
];

export const getProfile = [
  actions.getProfile,
  when(props`profile`, (val) => Boolean(val)),
  {
    true: [actions.setProfile],
    false: [actions.createProfile, actions.getProfile, actions.setProfile],
  },
];
