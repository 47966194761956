import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 50%;
  @media (max-width: 768px) {
    width: 80%;
  }
`;
export const Header = styled.h1`
  font-size: 4rem;
  margin: 0 auto;
  padding: 0.4em;
  margin-bottom: 1rem;
  text-align: center;
  font-weight: 800;
  width: fit-content;
  background-image: linear-gradient(to right, #ee2642 50px, transparent 50px),
    linear-gradient(to right, #ee2642 50px, transparent 50px),
    linear-gradient(to bottom, #ee2642 50px, transparent 50px),
    linear-gradient(to bottom, #ee2642 50px, transparent 50px);
  background-size: 100% 5px, 100% 5px, 5px 100%, 5px 100%;
  background-position: -25px 0%, -25px 100%, 0% -25px, 100% -25px;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
`;
export const SubHeader = styled.h2`
  font-size: 2rem;
  margin: 0;
  margin-bottom: 0rem;
  text-align: 0;
  font-weight: 900;
  width: 100%;
`;
export const Paragraph = styled.p`
  font-size: 1rem;
  margin-bottom: 1rem;
  line-height: 1.5rem;
  align-self: baseline;
`;
export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  height: 20rem;
  margin: 2rem 0;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 80%;
    height: auto;
  }
`;
export const Image = styled.img`
  height: 100%;
  width: auto;
  @media (max-width: 768px) {
    margin: 1rem 0;
    width: 100%;
    height: auto;
  }
`;
export const Link = styled.a`
  color: #ee2642;
  text-decoration: none;
  font-weight: bold;
  transition: opacity 0.2s ease-in-out;
  width: 100%;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`;
export const Button = styled.a`
  background-color: #ee2642;
  color: white;
  padding: 1rem 2rem;
  border-radius: 1000px;
  text-decoration: none;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: #c61e35;
  }
`;
export const MagazineContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1rem auto;
  width: 80%;
  text-align: center;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
export const MagazineImage = styled.img`
  width: 65%;
  text-align: center;
  transition: opacity 0.2s ease-in-out;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`;
