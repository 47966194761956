import React from 'react';
import { connect, ConnectedProps } from '@cerebral/react';
import { state } from 'client/app.cerebral';

import ImageWrapper from 'components/ImageWrapper';

import { Wrapper } from './elements';

import Image from './Image';

type Props = {
  className?: string;
};

const deps = {
  images: state.profile.images,
  user: state.app.user,
};

function Gallery({
  className,
  images,
  user,
}: Props & typeof deps & ConnectedProps): React.ReactElement {
  const imageComponents = Object.keys(images || {})
    .filter((imageKey) => images[imageKey].uid === user?.uid)
    .map((imageKey) => <Image key={imageKey} imageKey={imageKey} />);

  return (
    <Wrapper>
      <ImageWrapper className={className}>{imageComponents}</ImageWrapper>
    </Wrapper>
  );
}

export default connect<Props, typeof deps>(deps, Gallery);
