import { state, Context } from 'client/app.cerebral';

const day = 84000000;

export function updateEvents({ firebase }: Context) {
  return firebase.task('updateEvents');
}

export function getEvents({ firebase, path }: Context) {
  return firebase
    .value('events.list')
    .then((res) => path.success({ events: res.value }))
    .catch(path.error);
}

export function getLastUpdatedEvents({ firebase, path }: Context) {
  return firebase
    .value('events.lastUpdated')
    .then((res) => path.success({ lastUpdated: res.value || false }))
    .catch(path.error);
}

export function checkIfUpdate({ props, path }: Context) {
  return !props.lastUpdated || props.lastUpdated + day < Date.now()
    ? path.true()
    : path.false();
}

export function setEvents({ props, store }: Context) {
  store.set(state.events.list, props.events);
}
