import firstParagraph from './firstParagraph';
import thirdStep from './thirdStep';

export default {
  title: 'Bli med!',
  memberFee: 'Medlemsavgift',
  fillInForm: 'Fyll inn skjemaet for å bli med i SK',
  firstParagraph,
  form: {
    title: 'Send oss en mail!',
    name: 'Navn',
    email: 'E-post',
    message: 'Beskjed',
    subject: 'Emne',
    submit: 'Send',
  },
  pay: 'Medlemsavgiften kan overføres til følgende konto:',
  payCredentials: 'MARKDOWN:Venligst vedlegg **fult navn** når du betaler.',
  steps: 'For å registrere deg som medlem i Studentenes Kameraklubb:',
  firstStepTitle: '1. Lag en bruker og logg inn',
  firstStep:
    'Klikk på "Logg inn"-knappen øverst i nettleseren. Logg inn med Google-kontoen din.',
  secondStepTitle: '2. Motta en velkomstepost',
  secondStep: 'Du vil motta en velkomstepost med videre instruksjoner.',
  thirdStepTitle: '3. Betal medlemsavgiften',
  thirdStep,
};
