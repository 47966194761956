export default `MARKDOWN:
  Welcome to Studentenes Kameraklubb! We're a collective of studentens in 
  Trondheim who share a unified passion for photography. In our view, engaging 
  with fellow photographers is an excellent means to inspire and nurture one's 
  creativity. We therefore encourage you not to confine your photographic 
  pursuits to your home, but rather to share your enthusiasm with like-minded 
  individuals. Regardless of your preferred medium - be it analog, digital, 
  vintage equipment, or the latest smartphone - our club embraces all 
  enthusiasts. Whether your interests lie in mastering analog film development, 
  garnering appreciation on social media, or simply sharing your artistic 
  creations, we invite you to become a member of our club.
`;
