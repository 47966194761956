import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Wrapper = styled.div`
  position: relative;
  padding: 0;
  padding-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: start;
  font-size: 0.9rem;
  width: fit-content;
  max-height: 15rem;
  overflow-y: hidden;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 5rem;
    background: linear-gradient(transparent, white);
  }
`;

export const DateLabel = styled.p`
  flex-grow: 0;
  margin: 0;
  color: black;
  font-weight: bold;
`;

export const TimeLabel = styled.p`
  flex-grow: 0;
  text-align: center;
  margin: 5px;
`;

export const TimeWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

export const Spacer = styled.div`
  flex-grow: 1;
  border-right: 1px solid gray;
  align-self: center;
  flex-basis: 10px;
`;

export const Title = styled.h3`
  margin: 0;
  font-weight: bold;
`;

export const Line = styled.div`
  border-top: 1px solid gray;
  margin-bottom: 4px;
  width: 100%;
`;

export const Description = styled.p`
  white-space: pre-wrap;
  margin: 0;
  margin-left: 3rem;
  padding: 0;
  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

export const Location = styled.p`
  margin: 0;
  align-self: center;
`;

export const Info = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: start;
  width: 15rem;
  max-width: 15rem;
  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const Marker = styled(FontAwesomeIcon)`
  font-size: 1rem;
  margin: 4px 4px 4px 0;
  align-self: center;
  color: #ee2642;
`;

export const LocationWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const EventLink = styled.a`
  color: #e37b40;
  text-decoration: none;
  font-weight: bold;
  transition: opacity 0.2s ease-in-out;
  &:hover {
    opacity: 0.8;
  }
`;
