import styled from 'styled-components';

import EventsListComponent from './EventsList';
import AdminComponent from './Admin';

export const Header = styled.h1`
  font-size: 4rem;
  margin: 0 auto;
  padding: 0.4em;
  margin-bottom: 1rem;
  text-align: center;
  font-weight: 800;
  width: fit-content;
  background-image: linear-gradient(to right, #ee2642 50px, transparent 50px),
    linear-gradient(to right, #ee2642 50px, transparent 50px),
    linear-gradient(to bottom, #ee2642 50px, transparent 50px),
    linear-gradient(to bottom, #ee2642 50px, transparent 50px);
  background-size: 100% 5px, 100% 5px, 5px 100%, 5px 100%;
  background-position: -25px 0%, -25px 100%, 0% -25px, 100% -25px;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

export const EventsList = styled(EventsListComponent)``;

export const Wrapper = styled.div`
  max-width: 50%;
  margin: 0 auto;
  @media (max-width: 768px) {
    max-width: 90%;
  }
`;

export const Admin = styled(AdminComponent)``;
