import { Compute } from 'cerebral';
import { state } from 'client/app.cerebral';

export default Compute((get) => {
  const list = get(
    state.competition.competitions[state.competition.currentCompetition].images
  );
  const index = get(state.competition.slideIndex);
  const profiles = get(state.profiles);

  if (!list) return null;

  const imageKeys = Object.keys(list);
  const key = imageKeys[index % imageKeys.length];

  return {
    // photographer: profiles[list[key].uid].name,
    ...list[key],
    index: index,
    imageCount: imageKeys.length,
  };
});
