import { type Auth, getIdToken } from 'firebase/auth';
import { FirebaseProviderError } from './errors';

export default function getUserIDToken(auth: Auth) {
  const user = auth.currentUser;

  if (!user) {
    return Promise.reject(new FirebaseProviderError(new Error('No user')));
  }

  return getIdToken(user).catch((error) => {
    throw new FirebaseProviderError(error);
  });
}
