import React from 'react';
import { state } from 'client/app.cerebral';
import { connect, ConnectedProps } from '@cerebral/react';
import { Wrapper, Header, Table, Filters } from './elements';

import { useText } from 'common/hooks';

type Props = {
  className?: string;
};

const deps = {
  members: state.members.list,
};

function Members({
  className,
  members,
}: Props & typeof deps & ConnectedProps): React.ReactElement {
  const t = useText();

  return (
    <Wrapper className={className}>
      <Header>{t('members.title', { count: members.length || '...' })}</Header>
      <Filters />
      <Table />
    </Wrapper>
  );
}

export default connect<Props, typeof deps>(deps, Members);
