import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalComponent from 'common/Modal';
import ButtonComponent from 'components/Button';

const spin = keyframes`
   from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
`;

export const TableRow = styled.tr`
  &:nth-child(2n) {
    background-color: #dbdbdb;
  }
`;

type TableCellProps = {
  centered?: boolean;
};

export const TableCell = styled.td<TableCellProps>`
  text-align: ${({ centered }) => (centered ? 'center' : 'left')};
  padding: 0 10px;
`;

export const Icon = styled(FontAwesomeIcon)`
  margin: 0 10px;
`;

export const IconAnimated = styled(FontAwesomeIcon)`
  margin: 0 10px;
  animation-name: ${spin};
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;

export const IconClickable = styled(FontAwesomeIcon)`
  margin: 0 10px;

  cursor: pointer;
`;

export const Year = styled.input``;

export const Semester = styled.select``;

export const Option = styled.option``;

type UntilProps = {
  active?: boolean;
};

export const Until = styled.p<UntilProps>`
  color: ${({ active }) => (active ? 'green' : 'red')};
`;

export const Buttons = styled.div`
  display: flex;
  width: 100%;
`;

export const ModalText = styled.h2``;

export const Modal = styled(ModalComponent)`
  display: flex;
  align-items: center;
`;

export const Button = styled(ButtonComponent)`
  margin: 10px;
  flex-basis: 200px;
  flex-grow: 1;
`;
