import React, { useState } from 'react';
import { connect, ConnectedProps } from '@cerebral/react';
import { sequences, state } from 'client/app.cerebral';

import { faTrophy, faStar } from '@fortawesome/free-solid-svg-icons';

const icons = { faTrophy, faStar };

import {
  Wrapper,
  Name,
  Image,
  ImageWrapper,
  TopWrapper,
  AwardsWrapper,
  Award,
  AwardIcon,
  AwardCount,
  Status,
  Button,
  Code,
} from './elements';

import Gallery from './Gallery';

import { useText } from 'common/hooks';

const deps = {
  profile: state.app.profile,
  badges: state.profile.badges,
  isActiveMember: state.app.isActiveMember,
  logoutClicked: sequences`auth.logoutClicked`,
};

function Profile({
  profile,
  badges,
  isActiveMember,
  logoutClicked,
}: typeof deps & ConnectedProps): React.ReactElement {
  const t = useText();

  if (!profile) return <Name>{'Loading'}</Name>;

  const { year, semester } = isActiveMember;
  const [showCode, setShowCode] = useState(false);
  const code = '128';

  return (
    <Wrapper>
      <TopWrapper>
        <ImageWrapper>
          <Image alt={'Profile'} src={profile.imageUrl} />
        </ImageWrapper>
        <AwardsWrapper>
          {badges.map((badge) => (
            <Award key={badge.key}>
              <AwardIcon icon={icons[badge.icon]} color={badge.color} />
              <AwardCount>{badge.count}</AwardCount>
            </Award>
          ))}
        </AwardsWrapper>
        <Name>{profile.name}</Name>
        <Status active={isActiveMember.isActive}>
          {isActiveMember.isActive
            ? t('profile.active', {
                year,
                semester: t(`common.${semester}`),
              })
            : t('profile.inactive')}
        </Status>
        {isActiveMember.isActive ? (
          showCode ? (
            <Code>{code}</Code>
          ) : (
            <Button onClick={() => setShowCode(true)}>
              {t('profile.showCode')}
            </Button>
          )
        ) : null}
      </TopWrapper>
      <Gallery />
      <Button onClick={() => logoutClicked()}>{t('profile.logout')}</Button>
    </Wrapper>
  );
}

export default connect(deps, Profile);
