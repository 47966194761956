export default {
  newCompetition: {
    title: 'New gallery',
    form: {
      title: 'Title',
      description: 'Description',
      from: 'From',
      to: 'To',
      coverImage: 'Cover image',
      maxParticipants: 'Max images per person',
      isVisible: 'Images are visible to everyone',
      submit: 'Create new gallery',
      creating: 'Creating...',
    },
  },
  editCompetition: {
    title: 'Edit gallery',
    form: {
      submit: 'Edit gallery',
      saving: 'Saving...',
    },
  },
  newImage: {
    title: 'New image',
    form: {
      title: 'Title',
      image: 'Image',
      submit: 'Add new image',
      uploading: 'Uploading...',
    },
  },
  startPresentation: 'Start presentation',
  startPresentationShort: 'Presentation',
  endPresentation: 'End presentation',
  edit: 'Edit gallery',
  editShort: 'Edit',
  showImages: 'Show all images to everyone',
  showImagesShort: 'Show images',
  downloadImages: 'Download all images',
  downloadImagesShort: 'Download',
};
