import React from 'react';

import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

import {
  Wrapper,
  DateLabel,
  Info,
  Title,
  Location,
  Description,
  Marker,
  LocationWrapper,
} from './elements';

import { useFormatDate, useText } from 'common/hooks';
import { FormattedEvent } from 'modules/Events/types';

type Props = {
  event: FormattedEvent;
};

export default function Event({ event }: Props): React.ReactElement {
  const formatDate = useFormatDate();
  const t = useText();

  const facebookRegex =
    /https?:\/\/?www\.?facebook\.com\/events\/\d+\/?(?:\?event_time_id=\d+)?/u;
  const description = event.description.replace(facebookRegex, '');

  return (
    <Wrapper>
      <Info>
        <Title>{event.title}</Title>
        <DateLabel>{formatDate(event.fromDatetime, 'do MMM')}</DateLabel>
        <LocationWrapper>
          <Marker icon={faMapMarkerAlt} />
          <Location>{event.location || 'Lokasjon kommer'}</Location>
        </LocationWrapper>
      </Info>
      <Description>{description || 'Info kommer'}</Description>
    </Wrapper>
  );
}
