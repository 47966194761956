import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProgressBar as ProgressBarComponent } from 'react-bootstrap';

export const Wrapper = styled.div``;

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const IconLeft = styled(FontAwesomeIcon)`
  font-size: 16px;
  margin-right: 10px;
`;

export const IconRight = styled(FontAwesomeIcon)`
  font-size: 16px;
  margin-right: 10px;
  margin-left: 15px;
`;

export const Count = styled.p`
  font-size: 16px;
  margin: 0;
`;

export const DateComponent = styled.p`
  margin-bottom: 0;
`;

export const ProgressBar = styled(ProgressBarComponent)`
  flex-basis: 60px;
  margin: 0 10px;
  height: 10px;
`;
