export default {
  year: 'år',
  years: 'år',
  semester: 'semester',
  semesters: 'semestere',
  galleries: 'gallerier',
  photographer: 'fotograf',
  notLoggedIn: 'Log inn via menyen for å se og laste opp bilder.',
  notLoggedInBooking: 'Log inn via menyen for å booke utstyr.',
  photo: 'Foto',
  loadingCompetition: 'Laster galleri',
  downloadImages: 'Last ned bilder',
  showImages: 'Vis alle bilder',
  spring: 'våren',
  autumn: 'høsten',
};
