import styled from 'styled-components';

export const Link = styled.a`
  color: black;
  text-decoration: none;
  font-size: inherit;
  padding: 0.5em;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  border-radius: 100em;
  &:hover {
    background-color: #ee264220;
  }
  &:active {
    background-color: #ee264240;
  }
`;
export const Wrapper = styled.div`
  cursor: pointer;
`;
