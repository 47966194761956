import { Context, state } from 'client/app.cerebral';

export function openWithToken({ props }: Context) {
  window.open(`${props.url}?token=${props.token}`, '_blank');
}

export function getToken({ firebase }: Context) {
  return firebase.getUserIDToken().then((result) => ({
    token: result,
  }));
}

export function termsAccepted({ firebase }: Context) {
  return firebase.task('setTermsAccepted');
}

export function setTermsAccepted({ store, get }: Context) {
  const uid = get(state.app.uid);

  if (uid) {
    store.set(state.profiles[uid].termsAccepted, Date.now());
  }
}

export function sendMessage({ firebase, props }: Context) {
  return firebase.task(`sendMessage`, props.data);
}

export function getDownloadURL({
  firebase,
  props,
  path,
}: Context<{ path: string }>) {
  return firebase
    .getDownloadURL(`${props.path}.jpg`)
    .then((response) => path.success({ url: response }))
    .catch((error) => path.error({ error }));
}
