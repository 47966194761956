import React from 'react';
import { faImage, faUser } from '@fortawesome/free-regular-svg-icons';
import { connect, ConnectedProps } from '@cerebral/react';
import { state } from 'client/app.cerebral';

import {
  Wrapper,
  InnerWrapper,
  IconLeft,
  IconRight,
  Count,
  DateComponent,
  ProgressBar,
} from './elements';

import { useFormatDate } from 'common/hooks';

type Props = {
  className?: string;
};

const deps = {
  competition: state.competition.list[state.competition.currentCompetition],
  info: state.competition.info,
};

function InfoBar({
  className,
  info,
  competition,
}: Props & typeof deps & ConnectedProps): React.ReactElement {
  const progress =
    ((new Date().getTime() - competition.fromDatetime) /
      (competition.toDatetime - competition.fromDatetime)) *
    100;

  const formatDate = useFormatDate();

  return (
    <Wrapper className={className}>
      <InnerWrapper>
        <IconLeft icon={faImage} />
        <Count>{info.imageCount}</Count>
        <IconRight icon={faUser} />
        <Count>{info.participantCount}</Count>
      </InnerWrapper>
      <InnerWrapper>
        <DateComponent>
          {formatDate(competition.fromDatetime, 'do MMM HH:mm')}
        </DateComponent>
        <ProgressBar
          now={progress}
          variant={progress >= 100 ? 'danger' : 'success'}
        />
        <DateComponent>
          {formatDate(competition.toDatetime, 'do MMM HH:mm')}
        </DateComponent>
      </InnerWrapper>
    </Wrapper>
  );
}

export default connect<Props, typeof deps>(deps, InfoBar);
