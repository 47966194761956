import React from 'react';

import { useText } from 'common/hooks';

import { Wrapper, Header, EventsList, Admin } from './elements';

export default function Events() {
  const t = useText();

  return (
    <Wrapper>
      <Header>{t('events.events')}</Header>
      <Admin />
      <EventsList />
    </Wrapper>
  );
}
