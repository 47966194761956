import React from 'react';
import { state, props, sequences } from 'client/app.cerebral';
import { connect, ConnectedProps } from '@cerebral/react';
import {
  faCheck,
  faPencilAlt,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';

import Mails from './Mails';
import Status from './Status';

import { TableRow, TableCell, Icon, IconClickable } from './elements';

import { useFormatDate } from 'common/hooks';

type Props = {
  className?: string;
  uid: string;
};

const deps = {
  member: state.profiles[props`uid`],
  editing: state.members.edit.uid,
  semester: state.members.edit.semester,
  year: state.members.edit.year,
  editMember: sequences`members.editMember`,
  updateMemberUntil: sequences`members.updateMemberUntil`,
};

function TableRowComponent({
  className,
  member,
  editing,
  uid,
  editMember,
  updateMemberUntil,
}: Props & typeof deps & ConnectedProps): React.ReactElement {
  const isEditing = editing === uid;

  const formatDate = useFormatDate();

  return (
    <TableRow className={className}>
      <TableCell>{member.name}</TableCell>
      <TableCell>{member.email}</TableCell>
      <TableCell>
        <Mails uid={uid} />
      </TableCell>
      <TableCell>
        {member.termsAccepted
          ? formatDate(member.termsAccepted, 'do MMM HH:mm')
          : '-'}
      </TableCell>
      <TableCell centered>
        {member.isAdmin ? <Icon icon={faCheck} /> : null}
      </TableCell>
      <TableCell>
        <Status uid={uid} />
      </TableCell>
      <TableCell centered>
        {isEditing ? (
          <>
            <IconClickable
              onClick={() => editMember({ uid: null })}
              icon={faTimes}
            />
            <IconClickable onClick={() => updateMemberUntil()} icon={faCheck} />
          </>
        ) : (
          <IconClickable
            onClick={() => editMember({ uid })}
            icon={faPencilAlt}
          />
        )}
      </TableCell>
    </TableRow>
  );
}

export default connect<Props, typeof deps>(deps, TableRowComponent);
