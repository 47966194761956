import * as yup from 'yup';

const FILE_SIZE = 7000000;
const SUPPORTED_FORMATS = ['image/jpeg', 'image/jpg'];

export const createSchema = (edit?: boolean) =>
  yup.object().shape({
    title: yup.string().required('errors.required'),
    description: yup.string().required('errors.required'),
    fromDatetime: yup
      .date()
      .typeError('errors.required')
      .required('errors.required'),
    toDatetime: yup
      .date()
      .typeError('errors.required')
      .required('errors.required'),
    maxImages: yup
      .number()
      .integer('errors.integer')
      .min(1, 'errors.more_than_zero')
      .typeError('errors.required')
      .required('errors.required'),
    ...(!edit && {
      imageUrl: yup
        .mixed()
        .test(
          'requireFile',
          'errors.image_required',
          (value) => value && value[0]
        )
        .test(
          'fileSize',
          'errors.image_too_large',
          (value) => value && value[0] && value[0].size <= FILE_SIZE
        )
        .test(
          'fileFormat',
          'errors.image_unsupported_format',
          (value) =>
            value && value[0] && SUPPORTED_FORMATS.includes(value[0].type)
        ),
    }),
  });
