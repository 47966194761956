import { Context, state } from 'client/app.cerebral';

export function getProfiles({
  props,
  get,
  app,
}: Context<{ competition: { images: Record<string, { uid: string }> } }>) {
  const profiles = Object.keys(get(state.profiles));
  const images = props.competition?.images ?? {};

  return Object.keys(images)
    .map((imageKey) => images[imageKey].uid)
    .filter((p) => !profiles.includes(p))
    .forEach((uid) => {
      app.getSequence('app.getProfile')({ uid });
    });
}

export function listenForVisibilityChange({ props, firebase }: Context) {
  return firebase.onValue(
    `competitionList.${props.competitionKey}.isVisible`,
    `competition.competitionVisibilityChanged`,
    { payload: { competitionKey: props.competitionKey } }
  );
}

export function listenForImageAdded({ props, firebase }: Context) {
  return firebase.onChildAdded(
    `competitions.${props.competitionKey}.images`,
    `competition.imageAdded`
  );
}

export function listenForImageChanged({ props, firebase }: Context) {
  return firebase.onChildChanged(
    `competitions.${props.competitionKey}.images`,
    `competition.imageChanged`
  );
}

export function listenForImageRemoved({ props, firebase }: Context) {
  return firebase.onChildRemoved(
    `competitions.${props.competitionKey}.images`,
    `competition.imageRemoved`,
    { payload: { competitionKey: props.competitionKey } }
  );
}

export function incrementSlide({ store, get }: Context) {
  const current = get(state.competition.slideIndex);
  const competitionKey = get(state.competition.currentCompetition);
  const images = get(state.competition.competitions[competitionKey].images);
  const newIndex = Math.min(current + 1, Object.keys(images || {}).length - 1);

  store.set(state.competition.slideIndex, newIndex);
}

export function decrementSlide({ store, get }: Context) {
  store.set(
    state.competition.slideIndex,
    Math.max(0, get(state.competition.slideIndex) - 1)
  );
}

export function setImageUrl({
  props,
}: Context<{ onDone: (url: string) => void; data: { imageUrl: string } }>) {
  props.onDone(props.data.imageUrl);
}

export function removeImage({ firebase, get, path }: Context) {
  return firebase
    .task('removeImage', get(state.app.modal.payload))
    .then(path.success)
    .catch(path.error);
}

export function removeCompetition({ firebase, get, path }: Context) {
  return firebase
    .task('removeCompetition', get(state.app.modal.payload))
    .then(path.success)
    .catch(path.error);
}

export function toggleCompetitionVisibility({ firebase, props }: Context) {
  return firebase.task('toggleCompetitionVisibility', {
    competitionKey: props.competitionKey,
  });
}

export function setBadge({ firebase, props }: Context) {
  return firebase.task('setBadge', {
    uid: props.uid,
    imageKey: props.imageKey,
    badge: props.badge,
    competitionKey: props.competitionKey,
  });
}

export function getCompetitions({ firebase, path }: Context) {
  return firebase
    .value(`competitionList`)
    .then((response) => path.success({ competitions: response.value || {} }))
    .catch((error) => path.error({ error }));
}

export function getCompetition({ firebase, props, path }: Context) {
  return firebase
    .value(`competitions.${props.competitionKey}`)
    .then((response) => path.success({ competition: response.value || {} }))
    .catch((error) => path.error({ error }));
}
