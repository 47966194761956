import styled from 'styled-components';

export const Black = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(1, 1, 1, 0.7);
  z-index: 100;
`;

export const Modal = styled.div`
  max-width: 400px;
  max-height: 400px;
  background-color: white;
  padding: 30px;
`;

export const Title = styled.h2`
  text-align: center;
  margin-top: 0;
`;

type ButtonProps = {
  confirm?: boolean;
};

export const Button = styled.button<ButtonProps>`
  min-width: 150px;
  min-height: 50px;
  background-color: ${(props) => (props.confirm ? 'green' : 'red')};
  border-radius: 10px;
  border: none;
  & :hover {
    border-width: 3px;
  }
`;

export const Text = styled.p``;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const ButtonText = styled.p`
  font-size: 17px;
  color: white;
  margin: 0;
`;
