import React from 'react';

import { useText } from 'common/hooks';

import { Header, Paragraph, Wrapper, Link } from './elements';

export default function About() {
  const t = useText();

  return (
    <Wrapper>
      <Header>{t('notfound.title')}</Header>
      <Paragraph>{t('notfound.description')}</Paragraph>
      <Link href={'/'}>{t('notfound.homelink')}</Link>
    </Wrapper>
  );
}
