import React from 'react';
import { connect, ConnectedProps } from '@cerebral/react';
import { sequences, state } from 'client/app.cerebral';
import {
  faFlag as faFlagRegular,
  faEnvelope,
} from '@fortawesome/free-regular-svg-icons';
import { faFlag as faFlagSolid } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';

import {
  Wrapper,
  Column,
  Header,
  List,
  ContactImage,
  Link,
  Text,
} from './elements';

import { useText } from 'common/hooks';

type Props = {
  className?: string;
};

const deps = {
  currentLanguage: state.language,
  languageClicked: sequences`app.setLanguage`,
  isPresentation: state.competition.isPresentation,
};

function Footer({
  className,
  languageClicked,
  currentLanguage,
  isPresentation,
}: Props & typeof deps & ConnectedProps): React.ReactNode {
  if (isPresentation) return null;
  const t = useText();

  return (
    <Wrapper className={className}>
      <Column>
        <Header>{'Støttespillere'}</Header>
        <Link href="https://www.sit.no/" target={'_blank'} rel={'noreferrer'}>
          <Text>{'SiT'}</Text>
        </Link>
        <Link
          href="http://www.fotoimport.no/"
          target={'_blank'}
          rel={'noreferrer'}
        >
          <Text>{'Fotoimport AS'}</Text>
        </Link>
        <Link
          href="https://www.japanphoto.no/"
          target={'_blank'}
          rel={'noreferrer'}
        >
          <Text>{'CEWE Japan Photo'}</Text>
        </Link>
      </Column>
      <Column>
        <Header>
          <Link
            href="https://www.facebook.com/studenteneskameraklubb"
            target={'_blank'}
            rel={'noreferrer'}
          >
            <ContactImage icon={faFacebook} />
            {' Facebook'}
          </Link>
        </Header>
        <Header>
          <Link
            href="https://www.instagram.com/studenteneskameraklubb/"
            target={'_blank'}
            rel={'noreferrer'}
          >
            <ContactImage icon={faInstagram} />
            {' Instagram'}
          </Link>
        </Header>
        <Header>
          <Link href="mailto:mail@studenteneskameraklubb.no">
            <ContactImage icon={faEnvelope} />
            {' mail@studenteneskameraklubb.no'}
          </Link>
        </Header>
      </Column>
      <Column>
        <Header>{'Choose language'}</Header>
        <List
          onClick={() => languageClicked({ language: 'no' })}
          active={currentLanguage === 'no'}
        >
          <ContactImage
            icon={currentLanguage === 'no' ? faFlagSolid : faFlagRegular}
          />
          <Text>{' Norsk'}</Text>
        </List>
        <List
          onClick={() => languageClicked({ language: 'en' })}
          active={currentLanguage === 'en'}
        >
          <ContactImage
            icon={currentLanguage === 'en' ? faFlagSolid : faFlagRegular}
          />

          <Text>{' English'}</Text>
        </List>
      </Column>
      <Column>
        <Header>{t('footer.address')}</Header>
        <Text>{'Prestekragevegen 4'}</Text>
        <Text>{'7050 Trondheim'}</Text>
      </Column>
    </Wrapper>
  );
}

export default connect<Props, typeof deps>(deps, Footer);
