export default `MARKDOWN:
  Velkommen til Studentenes Kameraklubb Trondheim. Vi er en gjeng studenter som
  alle deler samme lidenskap: fotografi. Å møte andre fotointeresserte er den beste
  måten å være kreativ på! Ikke sitt hjemme å ta bilder av sokkene dine, del din
  lidenskap med andre! Analogt, digitalt, bestefars kamera, eller nyeste
  smartphone - alle måter å ta bilder på er topp! Hvis du vil lære deg
  hvordan man skal ta og
  fremkalle gamle analoge fotografier, hvis du er glad i likes under Instagram bildet
  ditt eller vil bare dele ditt kunstverk med andre er du nødt til å melde deg inni SK.
`;
