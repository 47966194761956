import React, { useState } from 'react';
import { getAuth } from 'firebase/auth';
import page from 'page';
import { connect, ConnectedProps } from '@cerebral/react';
import { state } from 'client/app.cerebral';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import configs from '../../../configs/client.json';

import { Wrapper, Header, Button, Error } from './elements';
import Input from 'common/Input';
import ImageInput from 'common/ImageInput';

import { useText } from 'common/hooks';
import { yupResolver } from '@hookform/resolvers/yup';

const config =
  configs[(process.env.SKK_ENV as keyof typeof configs) || 'skk-dev'];

const FILE_SIZE = 7000000;
const SUPPORTED_FORMATS = ['image/jpeg', 'image/jpg'];

const schema = yup.object().shape({
  title: yup.string().required('errors.required'),
  imageUrl: yup
    .mixed()
    .required('errors.image_required')
    .test('requireFile', 'errors.image_required', (value) => value && value[0])
    .test(
      'fileSize',
      'errors.image_too_large',
      (value) => value && value[0] && value[0].size <= FILE_SIZE
    )
    .test(
      'fileFormat',
      'errors.image_unsupported_format',
      (value) => value && value[0] && SUPPORTED_FORMATS.includes(value[0].type)
    ),
});

type Props = {
  className?: string;
};

const deps = {
  currentCompetition: state.competition.currentCompetition,
};

function NewImage({
  className,
  currentCompetition,
}: Props & typeof deps & ConnectedProps): React.ReactElement {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const t = useText();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<yup.InferType<typeof schema>>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  return (
    <Wrapper className={className}>
      <Header>{t('competition.newImage.title')}</Header>
      {error ? <Error>{t('errors.error', { error })}</Error> : null}
      <form
        onSubmit={handleSubmit((data, e) => {
          const formData = new FormData(e?.target);

          formData.set('competitionKey', currentCompetition);
          setIsLoading(true);

          return getAuth()
            ?.currentUser?.getIdToken()
            .then((token) =>
              fetch(`${config.functionsUrl}/newImage`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                  Authorization: `Bearer ${token}`,
                },
                body: formData,
              })
            )
            .then(() => page(`/competitions/${currentCompetition}`))
            .catch((err) => {
              setError(err);
              setIsLoading(false);
            });
        })}
      >
        <Input
          type={'text'}
          name={'title'}
          title={t('competition.newImage.form.title')}
          required
          register={register}
          error={errors.title?.message ? t(errors.title.message) : null}
        />

        <ImageInput
          name={'imageUrl'}
          title={t('competition.newImage.form.image')}
          required
          register={register}
          error={
            errors.imageUrl?.message
              ? t(errors.imageUrl.message as string)
              : null
          }
        />
        <Button type={'submit'} disabled={isLoading}>
          {t(
            isLoading
              ? 'competition.newImage.form.uploading'
              : 'competition.newImage.form.submit'
          )}
        </Button>
      </form>
    </Wrapper>
  );
}

export default connect<Props, typeof deps>(deps, NewImage);
