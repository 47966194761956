export default {
  title: 'Krøniken',
  firstParagraph: `MARKDOWN: *Krøniken* is Studentenes Kameraklubb's own photo magazine. Once a year, the *Krøniken* editorial team gathers the best photos from
  the club's members and publishes them in a beautiful magazine.`,
  subHeader: 'Submit pictures for the 4th edition: Light/shadow',
  secondParagraph: `MARKDOWN: 
  The theme for the 4th edition is ready!\n

  Think broadly, there are many possibilities and interpretations to be made.\n
  Light and shadow go hand in hand and are some of the most important elements in photography.
  Light illuminates the motive, and together with shadow it creates depth, texture and mood.
  It can be both natural and artificial. Shadows can come in many forms, and the way you use them can create drama or softness.
  You must understand light to get the shadows you want. 

  Submit images at the link below, in a folder with your name. To give info about the camera, film and image title, name your files like this:
  
  "NameCameraFilmTitle"
  
  **Send images by: September 16th**`,

  join: 'Sign up here!',
  submitImages: 'Submit images here!',
  oldEditions: 'Previous Editions',
  thirdParagraph: `MARKDOWN: Would you like to browse through older editions of *Krøniken*? Some of them are digitally arhived:`,
  secondEdition: '2nd Edition',
  buy: `MARKDOWN: Would you like to acquire a copy? Visit us in the basement or send us a message. The price for the 3rd edition is 50 kr for non members and second-time purchases for members.`,
};
