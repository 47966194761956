import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  border-top: 1px solid black;
  padding-top: 20px;
  font-size: 1rem;
  @media (max-width: 768px) {
    font-size: 0.8rem;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
`;
export const Column = styled.div`
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 30%;
    margin: 0 5%;
  }
`;
export const Header = styled.p``;

type ButtonProps = {
  active?: boolean;
};

export const List = styled.button<ButtonProps>`
  border: 0;
  background-color: inherit;
  cursor: ${({ active }) => (active ? 'normal' : 'pointer')};
  display: block;
  margin: 1em 0;
  font-size: inherit;
  & > p {
    display: inline;
  }
`;

type TextProps = {
  active?: boolean;
};

export const Text = styled.p<TextProps>`
  cursor: ${({ active }) => (active ? 'normal' : 'pointer')};
`;

export const ContactImage = styled(FontAwesomeIcon)``;
export const Link = styled.a`
  color: black;
  text-decoration: none;
`;
