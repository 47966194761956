import React from 'react';
import { state } from 'client/app.cerebral';
import { connect, ConnectedProps } from '@cerebral/react';

import { useText } from 'common/hooks';

import { Logo, Menu, MenuItem, Title } from './elements';
const deps = {
  user: state.app.user,
  isAdmin: state.app.isAdmin,
  isActiveMember: state.app.isActiveMember,
};
function NewMenu({
  user,
  isAdmin,
  isActiveMember,
}: typeof deps & ConnectedProps): React.ReactNode {
  const t = useText();

  return (
    <>
      <a href={'../'}>
        <Logo src={'/images/SK_logo_400.png'} alt={'SK logo'} />
      </a>
      <Title>{t('frontPage.title')}</Title>
      <Menu>
        <MenuItem href={'/events'}>{t('menu.events')}</MenuItem>
        <MenuItem href={'/join'}>{t('menu.joinUs')}</MenuItem>
        <MenuItem href={'/about'}>{t('menu.about')}</MenuItem>
        <MenuItem href={'/kroniken'}>{'Krøniken'}</MenuItem>
        {user && isActiveMember.isActive ? (
          <>
            <MenuItem href={'/competitions'}>{t('menu.competitions')}</MenuItem>
            <MenuItem href={'/booking'}>{t('menu.booking')}</MenuItem>
          </>
        ) : null}
        {isAdmin ? (
          <MenuItem href={'/members'}>{t('menu.members')}</MenuItem>
        ) : null}
      </Menu>
    </>
  );
}

export default connect(deps, NewMenu);
