import React from 'react';
import { connect, ConnectedProps } from '@cerebral/react';
import { state, sequences, props } from 'client/app.cerebral';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { memberDatetime } from 'utils/common';
import { Semester, Option, Year, Until, Icon } from '../elements';

import { useText } from 'common/hooks';

type Props = {
  uid: string;
};

const deps = {
  member: state.profiles[props`uid`],
  editing: state.members.edit.uid,
  semester: state.members.edit.semester,
  year: state.members.edit.year,
  setYear: sequences`members.setYear`,
  setSemester: sequences`members.setSemester`,
};

function Status({
  uid,
  member,
  editing,
  semester,
  year,
  setYear,
  setSemester,
}: Props & typeof deps & ConnectedProps): React.ReactElement {
  const isActive =
    member.memberUntil && memberDatetime(member.memberUntil) > Date.now();

  const t = useText();

  if (editing === uid)
    return (
      <>
        <Semester
          value={semester}
          onChange={(e) => setSemester({ semester: e.target.value })}
        >
          <Option value={'spring'}>
            {t('members.table.memberUntil.spring')}
          </Option>
          <Option value={'autumn'}>
            {t('members.table.memberUntil.autumn')}
          </Option>
        </Semester>
        <Year
          type={'number'}
          min={2010}
          max={2100}
          value={year}
          onChange={(e) => setYear({ year: e.target.value })}
        />
      </>
    );
  else if (member.memberUntil)
    return (
      <Until active={isActive}>
        {t(
          `members.table.memberUntil.semester.${member.memberUntil.semester}`,
          { year: member.memberUntil.year }
        )}
      </Until>
    );

  return (
    <Until active>
      <Icon icon={faTimes} />
    </Until>
  );
}

export default connect<Props, typeof deps>(deps, Status);
