import { props, state } from 'client/app.cerebral';
import { set, when } from 'cerebral/operators';

import * as actions from './actions';

export const get10Images = [
  actions.getImages,
  {
    error: [],
    success: [
      when(props`images`),
      {
        true: set(state.profile.images, props`images`),
        false: [],
      },
    ],
  },
];

export const profileRouted = get10Images;
