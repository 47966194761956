import React, { useState } from 'react';
import { Image, ImageWrapper, Feedback } from './elements';
import { InputWrapper, InputLabel, Input } from '../Input/elements';
import { UseFormRegister } from 'react-hook-form';

type Props = {
  className?: string;
  title: string | React.ReactNode;
  name: string;
  required?: boolean;
  error?: React.ReactNode;
  register: UseFormRegister<any>;
};

export default function ImageInput({
  className,
  title,
  name,
  required,
  error,
  register,
}: Props): React.ReactElement {
  const [url, setUrl] = useState('');

  return (
    <InputWrapper className={className}>
      <InputLabel>{title}</InputLabel>
      <Input
        required={required}
        type={'file'}
        accept={'image/jpeg'}
        isValid={!error}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...register(name)}
        onChange={(e) => {
          if (e.target.files?.[0]) {
            if (url) {
              URL.revokeObjectURL(url);
            }

            setUrl(URL.createObjectURL(e.target.files[0]));
          }
        }}
      />
      <Feedback>{error}</Feedback>
      <ImageWrapper>
        <Image url={url} />
      </ImageWrapper>
    </InputWrapper>
  );
}
