import React from 'react';

import { ButtonComponent } from './elements';

type Props = {
  className?: string;
  onClick: () => void;
  color?: string;
  children: React.ReactNode;
};

export default function Button({
  className,
  onClick,
  color,
  children,
}: Props): React.ReactElement {
  return (
    <ButtonComponent className={className} onClick={onClick} color={color}>
      {children}
    </ButtonComponent>
  );
}
