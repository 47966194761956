export default {
  title: 'Members (%{count})',
  table: {
    name: 'Name',
    email: 'E-mail',
    emails: 'E-mails',
    terms: 'Terms',
    isAdmin: 'Is admin',
    memberUntil: {
      title: 'Member until',
      spring: 'Spring',
      autumn: 'Autumn',
      semester: {
        spring: 'Spring %{year}',
        autumn: 'Autumn %{year}',
      },
    },
    newPayment: 'New payment',
  },
  filters: {
    hasBeenMembers: 'Members',
    currentMembers: 'Paying members',
  },
};
