import React from 'react';
import { connect, ConnectedProps } from '@cerebral/react';
import { state, sequences } from 'client/app.cerebral';

import { useText } from 'common/hooks';

import {
  Black,
  Modal,
  Title,
  Text,
  Button,
  ButtonWrapper,
  ButtonText,
} from './elements';

const deps = {
  modal: state.app.modal,
  onCancel: sequences`app.resetModal`,
  onConfirm: sequences`${state.app.modal.sequences}`,
};

function Modals({
  modal,
  onCancel,
  onConfirm,
}: typeof deps & ConnectedProps): React.ReactNode {
  const t = useText();

  if (!modal.type) {
    return null;
  }

  return (
    <Black>
      <Modal>
        <Title>{t(`modals.${modal.type}.title`)}</Title>
        <Text>{t(`modals.${modal.type}.text`)}</Text>
        <ButtonWrapper>
          <Button confirm onClick={() => onCancel()}>
            <ButtonText>{t(`modals.${modal.type}.deny`)}</ButtonText>
          </Button>
          <Button onClick={() => onConfirm()}>
            <ButtonText>{t(`modals.${modal.type}.confirm`)}</ButtonText>
          </Button>
        </ButtonWrapper>
      </Modal>
    </Black>
  );
}

export default connect(deps, Modals);
