export default `MARKDOWN:
Pay the membership fee to:
- Bank transfer: 4200 32 16513 (IBAN: NO5842003216513, BIC/SWIFT: SPTRNO22)
- Vipps: #622814

**Remember to mark the payment with your name!** 

Once we have confirmed your payment, you are officially a member of Studentenes Kameraklubb.
If you encounter any problems, please do not hesitate to contact us via email or Facebook.
`;
