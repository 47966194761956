import * as sequences from './sequences';
import { OwnState } from './types';

const state: OwnState = {
  isAuthenticating: false,
  errorMessage: null,
};

export default {
  state,
  sequences: {
    logoutClicked: sequences.signOut,
    loginClicked: sequences.signIn,
  },
};
