import { useContext } from 'react';

import LanguageContext from 'client/LanguageContext';
import createTextAPI from 'client/text';

export default function useText() {
  const language = useContext(LanguageContext);

  return createTextAPI({ locale: language });
}
