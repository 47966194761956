import { type Database, get } from 'firebase/database';
import { createRef } from './helpers';
import { FirebaseProviderError } from './errors';

export default function valueFactory(database: Database) {
  return function valueFunction(
    path: string,
    options: Parameters<typeof createRef>[2] & {
      payload?: Record<string, any>;
    } = {}
  ) {
    const ref = createRef(database, path, options);

    return get(ref)
      .then((snapshot) => {
        return {
          key: snapshot.key,
          value: snapshot.val(),
        };
      })
      .catch((error) => {
        throw new FirebaseProviderError(error);
      });
  };
}
