import React from 'react';
import { connect, ConnectedProps } from '@cerebral/react';
import { state, sequences } from 'client/app.cerebral';
import {
  faDesktop,
  faEdit,
  faEye,
  faEyeSlash,
  faDownload,
} from '@fortawesome/free-solid-svg-icons';

import { Wrapper, Icon, Text, InnerWrapper } from './elements';

import { useText } from 'common/hooks';

type Props = {
  className?: string;
};

const deps = {
  isAdmin: state.app.isAdmin,
  competition: state.competition.list[state.competition.currentCompetition],
  competitionKey: state.competition.currentCompetition,
  competitionVisibilityClicked: sequences`competition.competitionVisibilityClicked`,
  competitionPresentationClicked: sequences`competition.competitionPresentationClicked`,
  goTo: sequences`app.goTo`,
};

function MenuBar({
  className,
  competition,
  competitionKey,
  competitionVisibilityClicked,
  competitionPresentationClicked,
  isAdmin,
  goTo,
}: Props & typeof deps & ConnectedProps): React.ReactNode {
  if (!isAdmin) return null;

  const t = useText();

  return (
    <Wrapper className={className}>
      <InnerWrapper onClick={() => competitionPresentationClicked()}>
        <Icon
          title={t('competition.startPresentation') as string}
          icon={faDesktop}
        />
        <Text>{t('competition.startPresentationShort')}</Text>
      </InnerWrapper>
      <InnerWrapper
        onClick={() => goTo({ path: `/competitions/${competitionKey}/edit` })}
      >
        <Icon title={t('competition.edit') as string} icon={faEdit} />
        <Text>{t('competition.editShort')}</Text>
      </InnerWrapper>
      <InnerWrapper
        onClick={() => competitionVisibilityClicked({ competitionKey })}
      >
        <Icon
          title={t('competition.showImages') as string}
          icon={competition.isVisible ? faEye : faEyeSlash}
          visible={competition.isVisible}
        />
        <Text>{t('competition.showImagesShort')}</Text>
      </InnerWrapper>
      {/* <InnerWrapper>
        <Icon
          title={t('competition.downloadImages') as string}
          icon={faDownload}
        />
        <Text>{t('competition.downloadImagesShort')}</Text>
      </InnerWrapper> */}
    </Wrapper>
  );
}

export default connect<Props, typeof deps>(deps, MenuBar);
