import React from 'react';
import { connect, ConnectedProps } from '@cerebral/react';
import { state, sequences, props } from 'client/app.cerebral';

import { Wrapper, Header, HeaderWrapper, Image, Link } from './elements';

type Props = {
  className?: string;
  link: string;
};

const deps = {
  isAdmin: state.app.isAdmin,
  competition: state.competition.list[props`competitionKey`],
  removeCompetitionClicked: sequences`competition.removeCompetitionClicked`,
};

function CompetitionCard({
  className,
  competition,
  link,
}: Props & typeof deps & ConnectedProps): React.ReactElement {
  return (
    <Wrapper className={className}>
      <HeaderWrapper>
        <Link href={link}>
          <Header title={competition.title}>{competition.title}</Header>
        </Link>
      </HeaderWrapper>
      <Link href={link}>
        <Image path={competition.imageUrl} noClick />
      </Link>
    </Wrapper>
  );
}

export default connect<Props, typeof deps>(deps, CompetitionCard);
