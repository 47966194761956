import { type Auth, signOut } from 'firebase/auth';
import { FirebaseProviderError } from './errors';

export default function signOutFactory(auth: Auth) {
  return signOut(auth)
    .then(() => {
      for (const key in window.localStorage) {
        if (key.indexOf('firebase:authUser') === 0) {
          window.localStorage.removeItem(key);
        }
      }
    })
    .catch((error) => {
      throw new FirebaseProviderError(error);
    });
}
