import styled from 'styled-components';

export const Scroller = styled.div`
  overflow-y: scroll;
  border: 1px solid gray;
  padding: 10px;
`;

export const Title = styled.h2`
  text-align: center;
`;

export const Content = styled.p`
  white-space: pre-wrap;
`;

export const Heading = styled.h3``;

export const Accept = styled.button`
  flex-shrink: 0;
  margin-top: 10px;
  margin-left: auto;
  width: 140px;
  cursor: pointer;
`;
