import { set, when } from 'cerebral/operators';
import { state, props } from 'client/app.cerebral';
import type { TSequenceArray } from 'function-tree';

import * as actions from './actions';
import * as sequences from './sequences';

export function authenticate(cont: TSequenceArray = []): any[] {
  return [
    when(state.app.user, (user) => Boolean(user)),
    {
      true: [sequences.getProfile, cont],
      false: [
        set(state.auth.isAuthenticating, true),
        actions.getUser,
        {
          error: [set(state.auth.isAuthenticating, false)],
          success: [
            when(props`user`),
            {
              true: [
                actions.setUser,
                set(state.auth.isAuthenticating, false),
                sequences.getProfile,
              ],
              false: [],
            },
            cont,
          ],
        },
      ],
    },
  ];
}
